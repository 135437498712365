<template>
  <div>
    <div class="my-card-body">
      <b-field label="LoginID" label-position="on-border">
        <b-input
          v-model="reservation.LoginID"
          maxlength="10"
          required
        ></b-input>
      </b-field>
      <b-field label="Referencia" label-position="on-border">
        <b-input v-model="reservation.Referencia" maxlength="10"></b-input>
      </b-field>
      <b-field label="Nombre del Hotel" label-position="on-border">
        <b-input
          v-model="reservation.NombreHotel"
          maxlength="50"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Check-in / Check-out"
        class="spaced"
        label-position="on-border"
      >
        <b-datepicker v-model="dateRange" :range="true" required></b-datepicker>
      </b-field>
      <div class="spaced flex">
        <b-field label="Pais" label-position="on-border">
          <b-select :loading="loadingCatPaises" v-model="selectedPais" expanded>
            <option v-for="pais in catPaises" :value="pais.Id">
              {{ pais.Nombre }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Estado" label-position="on-border">
          <b-select
            :loading="loadingCatEstados"
            v-model="selectedEstado"
            expanded
          >
            <option v-for="estado in catEstados" :value="estado.Id">
              {{ estado.Nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <b-field label="Dirección" label-position="on-border">
        <b-input
          v-model="reservation.Direccion"
          maxlength="200"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Monto"
        class="main-extended"
        label-position="on-border"
        expanded
      >
        <b-input type="number" v-model="reservation.Monto" required></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "ReservationForm",
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      catPaises: [],
      loadingCatPaises: false,
      selectedPais: this.reservation.Pais || "",
      catEstados: [],
      loadingCatEstados: false,
      selectedEstado: this.reservation.EstadoUbicacion || "",
      dateRange:
        this.reservation.Checkin && this.reservation.Checkout
          ? [
              new Date(this.reservation.Checkin),
              new Date(this.reservation.Checkout),
            ]
          : null,
    };
  },
  watch: {
    dateRange(newRange) {
      if (newRange && newRange.length === 2) {
        this.reservation.Checkin = moment(newRange[0]).valueOf();
        this.reservation.Checkout = moment(newRange[1]).valueOf();
      } else {
        this.reservation.Checkin = null;
        this.reservation.Checkout = null;
      }
    },
    selectedPais(newPais) {
      this.reservation.Pais = newPais;
      this.getCatEstados();
    },
    selectedEstado(newestado) {
      this.reservation.EstadoUbicacion = newestado;
    },
  },
  mounted() {
    this.getCatPaises();
  },
  methods: {
    getCatPaises() {
      this.loadingCatPaises = true;
      axios
        .get(`${API_URL}/catalogue/CatPaises`)
        .then((response) => {
          this.catPaises = response.data;
          if (this.selectedPais) {
            this.getCatEstados();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingCatPaises = false;
        });
    },
    getCatEstados() {
      this.loadingCatEstados = true;
      axios
        .get(`${API_URL}/catalogue/CatEstados/${this.selectedPais}`)
        .then((response) => {
          this.catEstados = response.data;
          if (this.selectedEstado) {
            this.selectedEstado = this.reservation.EstadoUbicacion;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingCatEstados = false;
        });
    },
    submitReservation() {
      this.$emit("submit", this.reservation);
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  gap: 0.5rem;
}
.main-extended {
  flex-grow: 1;
}
.spaced {
  margin-bottom: 1.5rem !important;
}
.dropdown-menu {
  position: relative;
  z-index: 1050;
}
.flex > * {
  flex: 1 1 50%;
  box-sizing: border-box;
  width: 50%;
}
.control > .select {
  width: 100% !important;
}
.control > .select > select {
  width: 100% !important;
}
</style>
