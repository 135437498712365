<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">
          <i class="far fa-handshake"></i> {{ i18n.onlineRenewals[iso] }}
        </p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section>
      <b-tabs v-model="activeTab">
        <b-tab-item
          :label="i18n.advancedSearch[iso]"
          icon-pack="fas"
          icon="search"
        >
          <form action="" class="advance-search">
            <b-field label="Login ID" label-position="on-border">
              <b-input v-model="advancedFilters.LoginID" type="text" value="">
              </b-input>
            </b-field>
            <b-field :label="i18n.contract[iso]" label-position="on-border">
              <b-input
                v-model="advancedFilters.nroContract"
                type="text"
                value=""
              >
              </b-input>
            </b-field>
            <b-field :label="i18n.name[iso]" label-position="on-border">
              <b-input v-model="advancedFilters.Nombre" type="text" value="">
              </b-input>
            </b-field>
            <b-field :label="i18n.lastname[iso]" label-position="on-border">
              <b-input
                v-model="advancedFilters.ApellidoPaterno"
                type="text"
                value=""
              >
              </b-input>
            </b-field>
            <b-field :label="i18n.email[iso]" label-position="on-border">
              <b-input v-model="advancedFilters.Email" type="email" value="">
              </b-input>
            </b-field>
            <b-field
              :label="i18n.processingDate[iso]"
              label-position="on-border"
            >
              <b-datepicker
                v-model="advancedFilters.FechaProcesable"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="i18n.cancellationDate[iso]"
              label-position="on-border"
            >
              <b-datepicker
                v-model="advancedFilters.FechaCancelado"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="i18n.renovationDate[iso]"
              label-position="on-border"
            >
              <b-datepicker
                v-model="advancedFilters.FechaRenovacion"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
              >
              </b-datepicker>
            </b-field>
            <b-field label="Club" label-position="on-border">
              <b-select v-model="advancedFilters.club" expanded>
                <option :value="null"></option>
                <option
                  v-for="club in clubesName"
                  :value="club._id"
                  :key="club._id"
                >
                  {{ club.Nombre }}
                </option>
              </b-select>
            </b-field>
            <b-field
              :label="i18n.membershipStatus[iso]"
              label-position="on-border"
            >
              <b-select v-model="advancedFilters.status" expanded>
                <option :value="null"></option>
                <option :value="1">
                  {{ i18n.current[iso] }}
                </option>
                <option :value="0">
                  {{ i18n.expired[iso] }}
                </option>
              </b-select>
            </b-field>
            <b-field
              :label="i18n.contractStatus[iso]"
              label-position="on-border"
            >
              <b-select
                v-model="advancedFilters.contractStatus"
                @input="checkValidateAdvancedFilters"
                expanded
              >
                <option :value="null"></option>
                <option :value="1">
                  {{ i18n.pending[iso] }}
                </option>
                <option :value="2">
                  {{ i18n.procesable[iso] }}
                </option>
                <option :value="3">
                  {{ i18n.cancelled[iso] }}
                </option>
              </b-select>
            </b-field>
            <b-field :label="i18n.languageUser[iso]" label-position="on-border">
              <b-select v-model="advancedFilters.language" expanded>
                <option :value="null"></option>
                <option :value="'SP'">
                  {{ i18n.spanish[iso] }}
                </option>
                <option :value="'EN'">
                  {{ i18n.english[iso] }}
                </option>
              </b-select>
            </b-field>
          </form>
          <div class="button-container">
            <b-button
              icon-left="search"
              icon-pack="fas"
              type="is-info"
              @click="advancedSearch(false)"
            >
              {{ i18n.search[iso] }}
            </b-button>
          </div>
          <hr />
          <div class="button-container">
            <div>
              <h2>{{ i18n.months[iso][workMonth] }} {{ workYear }}</h2>
            </div>
            <div>
              <b-button
                icon-left="filter"
                icon-pack="fas"
                type="is-primary"
                @click="filterStage()"
              >
                {{ i18n.filterStage[iso] }} 1
              </b-button>
              <b-button
                icon-left="check-square"
                icon-pack="fas"
                type="is-success"
                @click="checkAllMembers()"
              >
                Marcar todos
              </b-button>
              <b-button
                icon-left="link"
                icon-pack="fas"
                type="is-success"
                @click="sendRenewalLinks()"
              >
                {{ i18n.sendLink[iso] }}
              </b-button>
            </div>

            <br />
            <br />
          </div>
          <div class="members-table">
            <b-table
              :data="members"
              :paginated="isPaginated"
              backend-pagination
              :total="total"
              :per-page="perPage"
              @page-change="onPageChange"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"
              :default-sort-direction="defaultSortDirection"
              :pagination-rounded="isPaginationRounded"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              :bordered="isBordered"
              :striped="true"
              :narrowed="isNarrowed"
              :hoverable="true"
              :loading="loadingMembers"
              :focusable="isFocusable"
              :mobile-cards="hasMobileCards"
            >
              <b-table-column v-slot="props">
                <b-checkbox
                  :native-value="props.row.ContratoId.LoginID"
                  v-model="selectedMembers"
                ></b-checkbox>
              </b-table-column>
              <b-table-column
                field="Numero"
                :label="i18n.contract[iso]"
                width="250"
                centered
                v-slot="props"
              >
                {{
                  (props.row.ContratoId && props.row.ContratoId.Numero) || "n/a"
                }}
              </b-table-column>

              <b-table-column
                field="user"
                label="LoginID"
                width="250"
                centered
                v-slot="props"
              >
                <div
                  v-html="
                    formatTextBySearch(
                      props.row.ContratoId && props.row.ContratoId.LoginID,
                      'LoginID'
                    ) || `n/a`
                  "
                ></div>
              </b-table-column>

              <b-table-column
                field="club"
                label="Club"
                width="250"
                centered
                v-slot="props"
              >
                {{
                  (props.row.ContratoId &&
                    props.row.ContratoId.ClubId &&
                    props.row.ContratoId.ClubId.Nombre) ||
                  "n/a"
                }}
              </b-table-column>

              <b-table-column
                field="expiredAt"
                :label="i18n.renovationDate[iso]"
                width="250"
                centered
                v-slot="props"
              >
                <b-tag
                  :type="
                    !props.row.Estado
                      ? `is-danger is-light`
                      : 'is-success is-light'
                  "
                  ><b>{{
                    formatDate(
                      props.row.ContratoId &&
                        props.row.ContratoId.FechaRenovacion
                    ) || "n/a"
                  }}</b></b-tag
                >
              </b-table-column>
              <b-table-column
                field="expiredAt"
                :label="i18n.paymentLinkStatus[iso]"
                width="250"
                centered
                v-slot="props"
              >
                {{
                  props.row.lastPaymentLink
                    ? props.row.lastPaymentLink.Estado
                    : "-"
                }}
              </b-table-column>

              <b-table-column
                label="Actions"
                v-slot="props"
                centered
                width="170"
              >
                <b-tooltip type="is-success is-light">
                  <b-button
                    icon-left="eye"
                    icon-pack="fas"
                    size="is-small"
                    type="is-success is-light"
                    @click="goPath(`/membership-profile?id=${props.row._id}`)"
                    style="margin-right: 10px"
                  ></b-button>
                  <template v-slot:content>
                    <b>{{ i18n.viewContract[iso] }}</b></template
                  >
                </b-tooltip>
              </b-table-column>
              <template #footer>
                <div class="columns">
                  <div class="column is-1">
                    <b-field :label="i18n.quantity[iso]">
                      <b-select v-model="perPage" @input="savePerPage" expanded>
                        <option :value="5">5</option>
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
                <!-- <div class="has-text-right">Footer</div> -->
              </template>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
    <!-- <pre>{{ formatCheckeds }}</pre> -->
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n, { LoginID } from "../utils/i18n";

import ModalEditMember from "./ModalEditMember.vue";
// import ModalCommentMember from "./ModalCommentMember.vue";

const today = new Date();

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      total: 0,
      loadingExportMembers: false,
      activeTab: 0,
      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      //Filters
      tabFilters: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      loadingClubes: false,
      itemsFilters: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },

      /**
       * Members
       */

      members: [],
      loadingMembers: false,
      selectedMemberIds: [],
      selectedMembers: [],

      /**
       * Modal
       */

      isActiveModalEditMember: false,
      dataModalEditMember: null,
      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
      workMonth: moment().month(),
      workYear: moment().year(),
    };
  },
  computed: {
    formatCheckeds() {
      return JSON.stringify(this.selectedMembers);
    },
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Tabs
    const tabFilters = localStorage.getItem("tabFilters");
    if (tabFilters === 1 || tabFilters === "1") this.tabFilters = 1;

    //Members
    this.listAllClubes();
    let stateMembers = localStorage.getItem("membersRenewals");
    stateMembers = JSON.parse(stateMembers);
    if (
      stateMembers &&
      stateMembers?.exp &&
      stateMembers.exp > moment().valueOf() &&
      stateMembers.lastSearchMembers &&
      stateMembers.lastSearchMembers !== "null"
    ) {
      this.searchMembers = stateMembers.lastSearchMembers;
      this.passValidateSearch = true;
      this.listAllMembers({}, true, stateMembers);
    } else this.listAllMembers();
  },
  components: {
    ModalEditMember,
    // ModalCommentMember,
  },
  watch: {
    selectedUsers: {
      handler() {
        console.log(this.selectedUsers);
      },
      deep: true,
    },
  },
  methods: {
    filterStage() {
      let startRange = moment().add(2, "months").startOf("month");
      let endRange = moment().add(2, "months").endOf("month");

      this.advancedFilters.FechaRenovacion = [
        startRange.toDate(),
        endRange.toDate(),
      ];

      this.listAllMembers(this.advancedFilters);
    },
    checkLoginID(checkbox) {
      console.log("Hola");
      console.log(checkbox);
    },
    async listAllMembers(filters = {}, isState = false, state = {}) {
      try {
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;

          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("membersRenewals", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async sendRenewalLinks() {
      const result = await axios.post(`${API_URL}/renewals/mass/create`, {
        LoginIDs: this.selectedMembers,
      });

      if (result) {
        alert("Renovaciones enviadas");
      }

      console.log(result);
    },
    async checkAllMembers(
      filters = this.advancedFilters,
      isState = false,
      state = {}
    ) {
      try {
        if (isState) {
          this.itemsFilters = state.itemsFilters;
        } else {
          let query = `?limit=${10000000}&page=${this.currentPage}`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          const checkMembers = data?.members || [];

          checkMembers.map((member) => {
            this.selectedMembers.push(member.ContratoId.LoginID);
          });
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },

    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      }
    },
    basicSearch(isExport = false) {
      if (isExport) {
        if (this.searchMembers && this.searchMembers !== "")
          this.exportAllMembers({
            rgx: this.searchMembers,
          });
        else this.exportAllMembers({});
      } else {
        this.currentPage = 1;
        if (this.searchMembers && this.searchMembers !== "")
          this.listAllMembers({
            rgx: this.searchMembers,
          });
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearAdvancedSearch() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaCancelado: [],
        FechaRenovacion: [],
        club: null,
        status: null,
        onlyHeadlines: null,
        language: null,
        contractStatus: null,
      };
      this.listAllMembers();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("MMM/DD/YYYY").toUpperCase();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          filters
        );

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
  },
};
</script>
