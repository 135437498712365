<template>
  <div class="card" style="width: 500px">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ i18n.createRole[iso] }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content" style="height: 500px; overflow: auto">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-field
              :label="`${i18n.name[iso]} - ${i18n.spanish[iso]}`"
              :label-position="labelPosition"
            >
              <b-input v-model="role.Nombre" maxlength="20"></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :label="`${i18n.name[iso]} - ${i18n.english[iso]}`"
              :label-position="labelPosition"
            >
              <b-input v-model="role.NombreEn" maxlength="20"></b-input>
            </b-field>
          </div>
          <!-- <div class="column is-12">
          </div> -->
          <div class="column is-12">
            <b-field :label="i18n.createRole__type[iso]">
              <div class="block">
                <!-- <b-radio v-model="role.Tipo" name="name" native-value="admin">
                  Admin
                </b-radio> -->
                <b-radio v-model="role.Tipo" name="name" native-value="user">
                  User
                </b-radio>
                <b-radio v-model="role.Tipo" name="name" native-value="staff">
                  Staff
                </b-radio>
                <b-radio v-model="role.Tipo" name="name" native-value="club">
                  Club
                </b-radio>
                <b-radio
                  v-model="role.Tipo"
                  name="name"
                  native-value="agency"
                  disabled
                >
                  Agency
                </b-radio>
              </div>
            </b-field>
          </div>
          <div class="column is-12" v-if="role.Tipo === 'staff'">
            <b-field :label="i18n.createRole__staff[iso]">
              <div class="block">
                <b-checkbox v-model="role.StaffModulos" native-value="renewals">
                  {{ i18n.renewals[iso] }}
                </b-checkbox>
                <b-checkbox
                  v-model="role.StaffModulos"
                  native-value="activations"
                >
                  {{ i18n.activations[iso] }}
                </b-checkbox>
              </div>
            </b-field>
          </div>
          <div class="column is-12" v-if="role.Tipo === 'club'">
            <b-field :label="i18n.createRole__club[iso]">
              <b-dropdown v-model="role.Clubes" multiple aria-role="list">
                <template #trigger>
                  <b-button type="is-primary" icon-right="menu-down">
                    Selected ({{ role.Clubes.length }})
                  </b-button>
                </template>

                <b-dropdown-item value="all" aria-role="listitem">
                  <span>{{ i18n.all[iso] }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :value="club._id"
                  aria-role="listitem"
                  v-for="club in clubes"
                >
                  <span>{{ club.Nombre }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
          <div class="column is-12"></div>
          <div class="column is-12">
            <!-- <hr /> -->
            <b-field :label="i18n.createRole__permissions[iso]"> </b-field>
          </div>
          <div class="column is-12">
            <div v-for="permission in permissions">
              <b-checkbox
                type="is-success"
                v-model="role.Permisos"
                :native-value="permission.mainItemModule"
              >
                {{ iso === "es" ? permission.Nombre : permission.NombreEn }}
              </b-checkbox>
              <div class="block">
                <b-checkbox
                  type="is-info"
                  v-model="role.Permisos"
                  :native-value="p.module"
                  v-for="p in permission.items"
                  :disabled="!role.Permisos.includes(permission.mainItemModule)"
                >
                  {{ p.module }}
                </b-checkbox>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        style="height: auto"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        :class="`card-footer-item button is-success is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        @click="create"
        ><b>{{ i18n.save[iso] }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";
import { permissions } from "../../utils/permissions";

export default {
  name: "VacancyBackofficeModalLogs",
  props: ["roles", "languages"],
  data() {
    return {
      labelPosition: "on-border",
      isOpen: true,
      logs: [],
      member: null,
      loading: false,
      clubes: [],
      permissions,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,

      //
      role: {
        Nombre: null,
        NombreEn: null,
        Tipo: null,
        Permisos: [],
        StaffModulos: [],
        Clubes: [],
        TarifaAgenciaFija: null,
        TarifaAgenciaVariable: null,
      },
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    await this.listClubes();
  },
  mounted() {},

  methods: {
    async create() {
      try {
        this.loading = true;
        await axios.post(`${API_URL}/system/create-role`, {
          ...this.role,
          CreadoPor: localStorage.getItem("user") || "system",
        });
        this.loading = false;
        this.$buefy.toast.open({
          message: this.i18n.createRole__msg1[this.iso],
          type: "is-success",
        });
        this.$emit("refreshRoles");
        this.$emit("refreshUsers");
        this.$emit("close");
      } catch (err) {
        this.loading = false;
        console.log(err);
        let msg = "Error al crear rol";
        if (err?.response?.data?.error) msg = err?.response?.data?.error;
        this.$buefy.toast.open({
          message: msg,
          type: "is-danger",
        });
      }
    },
    async listClubes() {
      try {
        // this.loading = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          Activo: true,
        });
        this.clubes = data?.clubes || [];
        // this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
        let msg = "Error al listar clubes";
        if (err?.response?.data?.error) msg = err?.response?.data?.error;
        this.$buefy.toast.open({
          message: msg,
          type: "is-danger",
        });
      }
    },
  },
};
</script>
