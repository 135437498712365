<template>
  <div class="card" style="width: 800px; height: auto">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ i18n.createUser[iso] }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-4">
            <b-field :label="i18n.name[iso]" :label-position="labelPosition">
              <b-input v-model="user.Nombre" maxlength="30"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field
              :label="i18n.lastname[iso]"
              :label-position="labelPosition"
            >
              <b-input v-model="user.Apellido" maxlength="30"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field :label="i18n.email[iso]" :label-position="labelPosition">
              <b-input v-model="user.Email" maxlength="50"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field :label="i18n.user[iso]" :label-position="labelPosition">
              <b-input v-model="user.Usuario" maxlength="12"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              :label="i18n.password[iso]"
              :label-position="labelPosition"
            >
              <b-input
                type="password"
                password-reveal
                v-model="user.Password"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field :label-position="labelPosition" :label="i18n.role[iso]">
              <b-select v-model="user.Rol" expanded>
                <option :value="null"></option>
                <option v-for="role in roles" :value="role._id" :key="role._id">
                  {{ role.Nombre }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              :label="i18n.language[iso]"
              :label-position="labelPosition"
            >
              <b-select v-model="user.Idioma" expanded>
                <option :value="null"></option>
                <option v-for="l in languages" :value="l.Id" :key="l.Id">
                  {{ iso === "es" ? l.Nombre : l.NombreEn }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        style="height: auto"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        :class="`card-footer-item button is-success is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        @click="create"
        ><b>{{ i18n.save[iso] }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";

export default {
  name: "VacancyBackofficeModalLogs",
  props: ["roles", "languages"],
  data() {
    return {
      labelPosition: "on-border",
      isOpen: true,
      logs: [],
      member: null,
      loading: false,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,

      //
      user: {
        Nombre: null,
        Apellido: null,
        Email: null,
        Usuario: null,
        Password: null,
        Rol: null,
        Idioma: null,
      },
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  mounted() {},

  methods: {
    async create() {
      try {
        this.loading = true;
        await axios.post(`${API_URL}/system/create-user`, {
          ...this.user,
          CreadoPor: localStorage.getItem("user") || "system_error",
        });
        this.loading = false;
        this.$emit("refresh");
        this.$emit("close");
      } catch (err) {
        this.loading = false;
        console.log(err);
        let msg = "Error al crear usuario";
        if (err?.response?.data?.error) msg = err?.response?.data?.error;
        this.$buefy.toast.open({
          message: msg,
          type: "is-danger",
        });
      }
    },
  },
};
</script>
