<template>
  <div>
    <section class="hero header-content">
      <div class="hero-body">
        <p class="title">{{ i18n.statistics[iso] }}</p>
      </div>
      <div class="smaller-div">
        <b-field :label="i18n.filterByDate[iso]" label-position="on-border">
          <b-datepicker
            placeholder="DD/MM/YYYY"
            :min-date="minDate"
            :max-date="maxDate"
            :locale="iso"
            range
          >
          </b-datepicker>
        </b-field>
      </div>
    </section>

    <section>
      <p class="subtitle">{{ i18n.renewalsReporting[iso] }}</p>

      <div class="columns is-multiline">
        <!-- Se genera el for del componente -->
        <CardStatistic
          v-for="(card, index) in cards"
          v-if="card.section === 'renewalsReporting'"
          :key="index"
          :title="card.title"
          :number="card.number"
          :icon="card.icon"
          :fieldIso="card.fieldIso"
          :color="card.color"
          :description="card.description"
          :format="card.format"
        />
      </div>
    </section>

    <section style="margin-top: 38px">
      <p class="subtitle">
        {{ i18n.recoveryTeam[iso] }}
      </p>

      <div class="columns is-multiline">
        <!-- Se genera el for del componente -->
        <CardStatistic
          v-for="(card, index) in cards"
          v-if="card.section === 'accountRecovery'"
          :key="index"
          :title="card.title"
          :number="card.number"
          :icon="card.icon"
          :fieldIso="card.fieldIso"
          :color="card.color"
          :description="card.description"
          :format="card.format"
        />
      </div>
    </section>
  </div>
</template>

<script>
import i18n from "../../utils/i18n";
import CardStatistic from "./CardStatistic.vue";

export default {
  data() {
    return {
      i18n,
      iso: null,

      /* Información*/
      cards: [
        {
          key: "totalWeklyProduction",
          number: 150,
          icon: "fa-chart-bar",
          format: "USD",
          fieldIso: "totalWeklyProduction",
          section: "renewalsReporting",
          color: "sky-blue",
          description:
            "Indicador del rendimiento semanal del equipo y el ritmo de las renovaciones.",
        },
        {
          key: "salesVolume",
          number: 200,
          icon: "fa-shopping-cart",
          format: "USD",
          fieldIso: "salesVolume",
          section: "renewalsReporting",
          color: "periwinkle",
          description: "Indicador básico del volumen de venta.",
        },
        {
          number: 300,
          icon: "fa-laptop",
          fieldIso: "appliedPromotions",
          section: "renewalsReporting",
          color: "golden-yellow",
          description:
            "Indicador de las ventas realizadas a través del envío masivo",
        },
        {
          number: 250,
          icon: "fa-tags",
          format: "USD",
          fieldIso: "averageTicket",
          section: "renewalsReporting",
          color: "mustard-yellow ",
          description: "Valor promedio de las transacciones realizadas.",
        },
        {
          number: 400,
          icon: "fa-boxes",
          format: "%",
          fieldIso: "conversionRate",
          section: "renewalsReporting",
          color: "sage-green",
          description: "Agencia con mayor conversión de renovaciones.",
        },
        {
          number: 180,
          icon: "fa-tachometer-alt",
          fieldIso: "yearsTicket",
          section: "renewalsReporting",
          color: "charcoal-blue",
          description: "Promedio de años renovados por transacción.",
        },
        {
          number: 220,
          icon: "fa-user-plus",
          fieldIso: "newClinets",
          section: "renewalsReporting",
          color: "fern-green",
          description: "Cuantos clientes renuevan sus servicios año con año.",
        },
        {
          number: 320,
          icon: "fa-chart-pie",
          format: "%",
          fieldIso: "retentionRate",
          section: "renewalsReporting",
          color: "ligth-blue-grey",
          description:
            "Número de cuentas que han sido exitosamente recuperadas dentro de un período específico",
        },
        /* Sección de equipo de recuperacion de cuentas */
        {
          number: 10,
          icon: "fa-thumbs-up",
          fieldIso: "recoveredAccounts",
          section: "accountRecovery",
          color: "liberty",
          description:
            "Número de cuentas que han sido exitosamente recuperadas dentro de un período específico.",
        },
        {
          number: 10,
          icon: "fa-chart-line",
          format: "%",
          fieldIso: "recoveryRate",
          section: "accountRecovery",
          color: "liberty",
          description:
            "Porcentaje de cuentas vencidas que se logran recuperar en comparación con el total de cuentas vencidas.",
        },
        {
          number: 10,
          icon: "fa-hand-holding-usd",
          fieldIso: "yearsForgiven",
          section: "accountRecovery",
          color: "liberty",
          description: "Total de años condonados",
        },
        {
          number: 10,
          icon: "fa-wallet",
          format: "USD",
          fieldIso: "amountRecovered",
          section: "accountRecovery",
          color: "liberty",
          description:
            "Total del valor monetario recuperado a través de las cuentas vencidas, incluyendo cualquier descuento aplicado.",
        },
        {
          number: 10,
          icon: "fa-stopwatch",
          fieldIso: "averageRecoveryTime",
          section: "accountRecovery",
          color: "liberty",
          description:
            "Tiempo promedio que tarda en recuperarse una cuenta desde el contacto inicial hasta la reactivación completa.",
        },
        {
          number: 10,
          icon: "fa-search",
          fieldIso: "analysisofExpiryReasons",
          section: "accountRecovery",
          color: "liberty",
          description:
            "Número de cuentas que han sido exitosamente recuperadas dentro de un período específico",
        },
      ],
    };
  },
  methods: {},
  components: {
    CardStatistic,
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
};
</script>

<style scoped>
.media-content {
  gap: 3rem;
}
.smaller-div {
  width: 13%;
}
.header-content {
  margin-bottom: 1.2rem;
}
</style>
