<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.listMembers[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <b-tabs v-model="tabFilters" position="is-centered" @input="changeTab">
        <!-- <b-tab-item>
          <template #header>
            <b-icon icon="lightning-bolt" type="is-danger"></b-icon>
            <b>{{ i18n.fastSearch[iso] }}</b>
          </template>
          <div class="columns">
            <div class="column is-4">
              <b-field
                :type="
                  !passValidateSearch && searchMembers && searchMembers !== ''
                    ? 'is-danger'
                    : passValidateSearch &&
                      searchMembers &&
                      searchMembers !== ''
                    ? 'is-info'
                    : ''
                "
                :message="
                  !passValidateSearch && searchMembers && searchMembers !== ''
                    ? i18n.messageValidateSearch[iso]
                    : ''
                "
                grouped
              >
                <b-input
                  :placeholder="i18n.search[iso]"
                  v-model="searchMembers"
                  type="text"
                  icon="search"
                  icon-pack="fas"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                  @icon-right-click="clearInputSearch"
                  @input="validateSearch"
                >
                </b-input>
                <p class="control">
                  <b-button
                    class="button is-info"
                    @click="basicSearch(false)"
                    :disabled="
                      passValidateSearch &&
                      searchMembers &&
                      searchMembers !== ''
                        ? false
                        : true
                    "
                    >{{ i18n.search[iso] }}</b-button
                  >
                </p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="file"
                icon-pack="fas"
                type="is-info is-light"
                @click="basicSearch(true)"
                expanded
                outlined
                :loading="loadingExportMembers"
                >{{ i18n.exportToCsv[iso] }}</b-button
              >
            </div>
          </div>
        </b-tab-item> -->

        <b-tab-item>
          <template #header>
            <b-icon icon="filter" pack="fas" type="is-info"></b-icon>
            <b>{{ i18n.advancedSearch[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field label-position="on-border" label="LoginID">
                <b-input
                  v-model="advancedFilters.LoginID"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.contract[iso]">
                <b-input
                  v-model="advancedFilters.nroContract"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.name[iso]">
                <b-input
                  v-model="advancedFilters.Nombre"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.lastname[iso]">
                <b-input
                  v-model="advancedFilters.ApellidoPaterno"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.email[iso]">
                <b-input
                  v-model="advancedFilters.Email"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.registrationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRegistro"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaRegistro.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaRegistro = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.purchaseDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaCompra"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaCompra"
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaCompra.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaCompra = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.processingDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaProcesable"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaProcesable.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaProcesable = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.renewalDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaRenovacion"
                >
                </b-datepicker>
              </b-field>
            </div> -->
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.cancellationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaCancelacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaCancelacion"
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaCancelacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaCancelacion = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field label-position="on-border" :label="'Club'">
                <b-select v-model="advancedFilters.club" expanded>
                  <option :value="null"></option>
                  <option
                    v-for="club in clubesName"
                    :value="club._id"
                    :key="club._id"
                  >
                    {{ club.Nombre }}
                  </option>
                </b-select>
              </b-field>
            </div> -->
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.subscriptionStatus[iso]"
              >
                <b-select expanded v-model="advancedFilters.status">
                  <option :value="null"></option>
                  <option :value="1">
                    {{ i18n.current[iso] }}
                  </option>
                  <option :value="0">
                    {{ i18n.expired[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div> -->
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.membershipStatus[iso]"
              >
                <b-select expanded v-model="advancedFilters.membershipStatus">
                  <option :value="null"></option>
                  <option :value="true">
                    {{ i18n.active[iso] }}
                  </option>
                  <option :value="false">
                    {{ i18n.inactive[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div> -->
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.contractStatus[iso]"
              >
                <b-select
                  expanded
                  v-model="advancedFilters.contractStatus"
                  @input="checkValidateAdvancedFilters"
                >
                  <option :value="null"></option>
                  <option :value="1">
                    {{ i18n.pending[iso] }}
                  </option>
                  <option :value="2">
                    {{ i18n.procesable[iso] }}
                  </option>
                  <option :value="3">
                    {{ i18n.cancelled[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.languageUser[iso]"
              >
                <b-select expanded v-model="advancedFilters.language">
                  <option :value="null"></option>
                  <option :value="'SP'">
                    {{ i18n.spanish[iso] }}
                  </option>
                  <option :value="'EN'">
                    {{ i18n.english[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div> -->
            <div class="column is-6"></div>
            <!-- <div class="column is-2">
                <b-switch
                  :rounded="false"
                  size="is-medium"
                  v-model="advancedFilters.onlyHeadlines"
                  :true-value="true"
                  :false-value="false"
                  type="is-success"
                >
                  {{ i18n.onlyHeadlines[iso] }}
                </b-switch>
              </div> -->
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-success"
                @click="advancedSearch(false)"
                expanded
                >{{ i18n.search[iso] }}</b-button
              >
            </div>
            <div class="column is-3">
              <b-button
                icon-left="redo"
                icon-pack="fas"
                type="is-success is-light"
                @click="clearAdvancedSearch"
                expanded
                >{{ i18n.resetSearch[iso] }}</b-button
              >
            </div>
            <div class="column is-2">
              <b-button
                icon-left="file"
                icon-pack="fas"
                type="is-info is-light"
                @click="advancedSearch(true)"
                expanded
                outlined
                :loading="loadingExportMembers"
                >{{ i18n.exportToCsv[iso] }}</b-button
              >
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <hr />
      <b-table
        :data="members"
        :paginated="isPaginated"
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :bordered="isBordered"
        :striped="true"
        :narrowed="isNarrowed"
        :hoverable="true"
        :loading="loadingMembers"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        detailed
      >
        <template #detail="props">
          <b-table
            :data="getMembersStatic(props.row)"
            :bordered="true"
            :striped="false"
            :narrowed="isNarrowed"
            :hoverable="true"
            :focusable="isFocusable"
          >
            <b-table-column
              field="Nombre"
              :label="i18n.name[iso]"
              width="250"
              centered
              v-slot="propss"
            >
              <div
                v-html="
                  `${
                    formatTextBySearch(propss.row.Nombre, 'Nombre') || 'n/a'
                  } ${
                    formatTextBySearch(
                      propss.row.ApellidoPaterno,
                      'ApellidoPaterno'
                    ) || 'n/a'
                  }`
                "
              ></div>
              <!-- {{ propss.row.FullNombre }} -->
            </b-table-column>
            <b-table-column
              field="Telefono"
              :label="i18n.phone[iso]"
              width="250"
              centered
              v-slot="propss"
            >
              {{ propss.row.Telefono }}
            </b-table-column>
            <b-table-column
              field="Email"
              label="Email"
              width="250"
              centered
              v-slot="propss"
            >
              <div
                v-html="formatTextBySearch(propss.row.Email, 'Email') || 'n/a'"
              ></div>
              <!-- {{ propss.row.Email }} -->
            </b-table-column>
            <b-table-column
              field="Language"
              :label="i18n.language[iso]"
              width="250"
              centered
              v-slot="propss"
            >
              {{ propss.row.Language }}
            </b-table-column>
            <b-table-column
              field="TipoSocioId"
              :label="i18n.memberType[iso]"
              width="250"
              centered
              v-slot="propss"
            >
              <b-tag v-if="propss.row.TipoSocioId === 'T'" type="is-success">{{
                i18n.owner[iso]
              }}</b-tag>
              <b-tag v-else type="is-warning">{{ i18n.coOwner[iso] }}</b-tag>
              <!-- {{ propss.row.TipoSocioId }} -->
            </b-table-column>

            <!-- <b-table-column label="Actions" v-slot="props" centered width="170">
                <b-tooltip type="is-success is-light">
                  <b-button
                    icon-left="eye"
                    icon-pack="fas"
                    size="is-small"
                    type="is-success is-light"
                    v-if="props.row.TipoSocioId === 'T'"
                    @click="
                      goPath(`/membership-profile?id=${props.row._id}&view=owner`)
                    "
                    style="margin-right: 10px"
                  ></b-button>
                  <template v-slot:content>
                    <b>{{ i18n.viewProfile[iso] }}</b></template
                  >
                </b-tooltip>
                <b-tooltip type="is-info is-light">
                  <b-button
                    icon-left="sync"
                    icon-pack="fas"
                    size="is-small"
                    type="is-info is-light"
                    v-if="props.row.TipoSocioId === 'T'"
                    @click="restorePassword"
                  ></b-button>
                  <template v-slot:content>
                    <b>{{ i18n.resetPassword[iso] }}</b></template
                  >
                </b-tooltip>
              </b-table-column> -->
          </b-table>
          <b-table
            :data="getBeneficiariesStatic(props.row)"
            :bordered="true"
            :striped="false"
            :narrowed="isNarrowed"
            :hoverable="true"
            :focusable="isFocusable"
            v-if="getBeneficiariesStatic(props.row).length > 0"
          >
            <b-table-column
              field="Fullname"
              :label="i18n.beneficiaries[iso]"
              width="250"
              v-slot="propsss"
            >
              <div v-html="propsss.row"></div>
              <!-- {{ propsss.row }} -->
            </b-table-column>
          </b-table>
        </template>

        <b-table-column
          field="Numero"
          :label="i18n.contract[iso]"
          width="250"
          centered
          v-slot="props"
        >
          {{ (props.row.ContratoId && props.row.ContratoId.Numero) || "n/a" }}
        </b-table-column>

        <b-table-column
          field="user"
          label="LoginID"
          width="250"
          centered
          v-slot="props"
        >
          <div
            v-html="
              formatTextBySearch(
                props.row.ContratoId && props.row.ContratoId.LoginID,
                'LoginID'
              ) || `n/a`
            "
          ></div>
        </b-table-column>

        <b-table-column
          field="club"
          :label="i18n.owner[iso]"
          width="250"
          centered
          v-slot="props"
        >
          {{ `${props.row.Nombre || ""} ${props.row.ApellidoPaterno || ""}` }}
        </b-table-column>

        <b-table-column
          field="expiredAt"
          :label="i18n.registrationDate[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <b-tag type="is-info is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaRegistro
              ) || "n/a"
            }}</b></b-tag
          >
        </b-table-column>

        <b-table-column
          field="expiredAt"
          :label="i18n.purchaseDate[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <b-tag type="is-primary is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaCompra
              ) || "n/a"
            }}</b></b-tag
          >
        </b-table-column>

        <b-table-column
          field="expiredAt"
          :label="i18n.processableDate[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <b-tag type="is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaProcesable
              ) || ""
            }}</b></b-tag
          >
        </b-table-column>

        <b-table-column
          field="expiredAt"
          :label="i18n.contractStatus[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <b-tag
            :type="
              props.row.ContratoId && props.row.ContratoId.EstadoContrato === 3
                ? 'is-danger is-light'
                : props.row.ContratoId &&
                  props.row.ContratoId.EstadoContrato === 2
                ? 'is-success is-light'
                : 'is-info is-light'
            "
            ><b>{{
              props.row.ContratoId && props.row.ContratoId.EstadoContrato === 3
                ? i18n.cancelled[iso]
                : props.row.ContratoId &&
                  props.row.ContratoId.EstadoContrato === 2
                ? i18n.procesable[iso]
                : i18n.pending[iso]
            }}</b></b-tag
          >
        </b-table-column>

        <!-- <b-table-column
          field="Numero"
          :label="i18n.language[iso]"
          width="250"
          centered
          v-slot="props"
        >
          {{
            (props.row.ContratoId && props.row.ContratoId.Idioma === "ES"
              ? i18n.spanish[iso]
              : i18n.english[iso]) || "n/a"
          }}
        </b-table-column> -->

        <!-- <b-table-column
            field="TipoSocioId"
            :label="i18n.memberType[iso]"
            width="250"
            centered
            v-slot="props"
          >
            <b-tag v-if="props.row.TipoSocioId === 'T'" type="is-success">{{
              i18n.owner[iso]
            }}</b-tag>
            <b-tag v-else type="is-warning">{{ i18n.coOwner[iso] }}</b-tag>
          </b-table-column> -->

        <b-table-column label="Actions" v-slot="props" centered width="170">
          <b-tooltip type="is-success is-light">
            <b-button
              icon-left="eye"
              icon-pack="fas"
              size="is-small"
              type="is-success is-light"
              @click="goPath(`/profile-member?id=${props.row._id}`)"
              style="margin-right: 10px"
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.viewContract[iso] }}</b></template
            >
          </b-tooltip>
        </b-table-column>

        <template #footer>
          <div class="columns">
            <div class="column is-11"></div>

            <div class="column is-1">
              <b-field :label="i18n.quantity[iso]">
                <b-select v-model="perPage" @input="savePerPage" expanded>
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <!-- <div class="has-text-right">Footer</div> -->
        </template>
      </b-table>
      <b-modal
        v-model="isActiveModalEditMember"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <template #default="props">
          <modal-edit-member
            :__member="dataModalEditMember"
            @refresh="listAllMembers"
            @close="props.close"
          ></modal-edit-member>
        </template>
      </b-modal>
      <!-- <b-modal
          v-model="isActiveModalCommentMember"
          has-modal-card
          trap-focus
          aria-role="dialog"
          aria-modal
        >
          <template #default="props">
            <modal-comment-member
              :memberId="memberIdModalCommentMember"
              @close="props.close"
            ></modal-comment-member>
          </template>
        </b-modal> -->
    </section>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";

import ModalEditMember from "../ModalEditMember.vue";
// import ModalCommentMember from "./ModalCommentMember.vue";

const today = new Date();

const DEFAULT_PARAMS = {
  // ClubId: "96213bc2-b460-414e-980a-d0bd99d0869c",
  Periodicidad: "96560a5d-73c3-4117-a49a-2998941af654",
  Tarifa: 199,
  Moneda: "USD",
  // _id: "660a432c10345fc8fb2c2a08",
};

export default {
  data() {
    return {
      DEFAULT_PARAMS,
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      total: 0,
      loadingExportMembers: false,

      //i18n
      i18n,
      iso: "en",

      //Permissions
      permissions: "",

      //Filters
      tabFilters: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      loadingClubes: false,
      itemsFilters: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        FechaCompra: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
        membershipStatus: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },

      /**
       * Members
       */

      members: [],
      loadingMembers: false,

      /**
       * Modal
       */

      isActiveModalEditMember: false,
      dataModalEditMember: null,
      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
    };
  },
  async created() {
    this.$bus.on("change-club", () => {
      this.getClub();
    });
    await this.getClub();

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Tabs
    // const tabFilters = localStorage.getItem("tabFilters");
    // if (tabFilters === 1 || tabFilters === "1") this.tabFilters = 1;

    //Members
    // this.listAllClubes();
    // let stateMembers = localStorage.getItem("members");
    // stateMembers = JSON.parse(stateMembers);
    // console.log("stateMembers", stateMembers);
    // if (
    //   stateMembers &&
    //   stateMembers?.exp &&
    //   stateMembers.exp > moment().valueOf() &&
    //   stateMembers.lastSearchMembers &&
    //   stateMembers.lastSearchMembers !== "null"
    // ) {
    //   this.searchMembers = stateMembers.lastSearchMembers;
    //   this.passValidateSearch = true;
    //   this.listAllMembers({}, true, stateMembers);
    // } else this.listAllMembers();

    await this.listAllMembers();

    this.$forceUpdate();
  },
  components: {
    ModalEditMember,
    // ModalCommentMember,
  },
  methods: {
    async listAllMembers(filters = {}, isState = false, state = {}) {
      try {
        console.log("isState", isState);
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            {
              ...filters,
              club: this.DEFAULT_PARAMS._id,
            }
          );
          console.log("members", data);
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;

          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("members", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      }
    },
    basicSearch(isExport = false) {
      if (isExport) {
        if (this.searchMembers && this.searchMembers !== "")
          this.exportAllMembers({
            rgx: this.searchMembers,
          });
        else this.exportAllMembers({});
      } else {
        this.currentPage = 1;
        if (this.searchMembers && this.searchMembers !== "")
          this.listAllMembers({
            rgx: this.searchMembers,
          });
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearAdvancedSearch() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaCancelacion: [],
        FechaRenovacion: [],
        club: null,
        status: null,
        onlyHeadlines: null,
        language: null,
        contractStatus: null,
        membershipStatus: null,
      };
      this.listAllMembers();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data?.Idioma || "EN",
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.coOwner?.Idioma || "EN",
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("ll").toUpperCase();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      console.log("result", result);
      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s&cap=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          {
            ...filters,
            club: this.DEFAULT_PARAMS._id,
          }
        );
        console.log("members", data);

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    getClub() {
      let club = localStorage.getItem("club");
      club = club ? JSON.parse(club) : {};
      this.club = club;

      this.DEFAULT_PARAMS = {
        ClubId: club?.Id || null,
        _id: club?._id || null,
        ...this.DEFAULT_PARAMS,
      };
    },
  },
};
</script>
