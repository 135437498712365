<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="content">
        <h3>{{ i18n.documents[iso] }}</h3>
      </div>
    </div>
    <b-table
      :data="templateDocuments || []"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :bordered="isBordered"
      :striped="false"
      :narrowed="isNarrowed"
      :hoverable="false"
      :loading="loadingReserves"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
    >
      <b-table-column
        :label="i18n.document[iso]"
        centered
        v-slot="props"
        width="200"
      >
        <b>{{
          i18n[
            club &&
            club.Id === "24C6D42E-9A7A-41EA-BF35-7B81FD4D2343" &&
            props.row.Referencia === "acre"
              ? "authorizationPayments"
              : props.row.Referencia
          ][iso]
        }}</b>
      </b-table-column>

      <b-table-column
        :label="i18n.name[iso]"
        centered
        v-slot="props"
        width="200"
      >
        <small v-if="!props.row.Nombre">{{ "" }}</small>
        <small v-else-if="props.row.Nombre && props.row.Nombre.length < 40">{{
          props.row.Nombre || ""
        }}</small>
        <b-tooltip type="is-light" position="is-bottom" multilined v-else>
          <b-tag type="is-light">
            <small
              >{{
                props.row.Nombre && props.row.Nombre.substring(0, 40)
              }}...</small
            >
          </b-tag>
          <template v-slot:content>
            <b>{{ parseLongText(props.row.Nombre) }}</b>
          </template>
        </b-tooltip>
      </b-table-column>

      <b-table-column
        :label="i18n.dateUpload[iso]"
        centered
        v-slot="props"
        width="150"
      >
        <b-tag type="is-light" v-if="props.row.FechaCreacion">{{
          parseDate(props.row.FechaCreacion)
        }}</b-tag>
        <div v-else></div>
      </b-table-column>

      <b-table-column
        :label="i18n.uploadedBy[iso]"
        centered
        v-slot="props"
        width="150"
      >
        {{ props.row.CreadoPor || "" }}
      </b-table-column>

      <b-table-column
        :label="i18n.status[iso]"
        centered
        v-slot="props"
        width="150"
      >
        <b-tag type="is-success is-light" v-if="props.row.Id">{{
          i18n.uploadedDocument[iso]
        }}</b-tag>
        <b-tag type="is-danger is-light" v-else>{{
          i18n.pendingUpload[iso]
        }}</b-tag>
      </b-table-column>

      <b-table-column label="Actions" v-slot="props" centered width="150">
        <b-tooltip type="is-primary is-light">
          <a
            style="margin-right: 10px; cursor: pointer"
            @click="downloadPdfOwners(props.row.Referencia)"
          >
            <b-icon
              pack="fas"
              icon="spinner"
              custom-class="fa-spin"
              type="is-primary is-light"
              v-if="loadingGenerateDocument === props.row.Referencia"
            >
            </b-icon>
            <b-icon
              pack="fas"
              icon="file-pdf"
              size="is-small"
              type="is-primary is-light"
              v-else
            >
            </b-icon>
          </a>
          <template v-slot:content>
            <b>{{ i18n.generateDocument[iso] }}</b></template
          >
        </b-tooltip>
        <b-tooltip type="is-success is-light" v-if="!props.row.Id">
          <b-icon
            pack="fas"
            icon="spinner"
            custom-class="fa-spin"
            type="is-success is-light"
            v-if="loadingUploadDocument === props.row.Referencia"
          >
          </b-icon>
          <b-upload
            v-model="file"
            expanded
            @input="uploadFile(props.row.Referencia)"
            accept="application/pdf"
            v-else
          >
            <a style="margin-right: 10px; cursor: pointer">
              <b-icon
                pack="fas"
                icon="file-upload"
                size="is-small"
                type="is-success is-light"
              ></b-icon>
            </a>
          </b-upload>
          <template v-slot:content>
            <b>{{ i18n.uploadDocument[iso] }}</b></template
          >
        </b-tooltip>
        <b-tooltip type="is-info is-light" v-if="props.row.Id">
          <b-icon
            pack="fas"
            icon="spinner"
            custom-class="fa-spin"
            type="is-info is-light"
            v-if="loadingDownloadDocument === props.row.Referencia"
          >
          </b-icon>
          <a
            style="margin-right: 10px; cursor: pointer"
            @click="
              downloadFile(props.row.Referencia, props.row.Id, props.row.Nombre)
            "
            v-else
          >
            <b-icon
              pack="fas"
              icon="file-download"
              size="is-small"
              type="is-info is-light"
            >
            </b-icon>
          </a>
          <template v-slot:content>
            <b>{{ i18n.downloadDocument[iso] }}</b></template
          >
        </b-tooltip>
        <b-tooltip type="is-danger is-light" v-if="props.row.Id">
          <b-icon
            pack="fas"
            icon="spinner"
            custom-class="fa-spin"
            type="is-danger is-light"
            v-if="loadingRemoveDocument === props.row.Referencia"
          >
          </b-icon>
          <a
            style="cursor: pointer"
            @click="removeDocument(props.row.Referencia, props.row.Id)"
            v-else
          >
            <b-icon
              pack="fas"
              icon="trash"
              size="is-small"
              type="is-danger is-light"
            >
            </b-icon>
          </a>
          <template v-slot:content>
            <b>{{ i18n.removeDocument[iso] }}</b></template
          >
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>

  <!-- <div style="display: flex">
    <div>
      <p class="title is-4">{{ i18n.documents[iso] }}</p>
      <hr />
      <div class="buttons-column">
        <b-button type="is-primary" class="is-fullwidth mb-3"
          >Licencia de Usuario</b-button
        >
        <b-button type="is-primary" class="is-fullwidth mb-3"
          >Contrato</b-button
        >
        <b-button type="is-primary" class="is-fullwidth mb-3"
          >Friends and Family</b-button
        >
        <b-button type="is-primary" class="is-fullwidth mb-3">ADP</b-button>
        <b-button type="is-primary" class="is-fullwidth mb-3"
          >Compra de Villa</b-button
        >
        <b-button type="is-primary" class="is-fullwidth mb-3"
          >Cash Out</b-button
        >
        <b-button type="is-primary" class="is-fullwidth mb-3">Pagare</b-button>
        <b-button type="is-primary" class="is-fullwidth mb-3">CDP</b-button>
      </div>
    </div>
    <div class="vertical-line"></div>
    <div style="width: 100%">
      <p class="title is-4">{{ i18n.listDocuments[iso] }}</p>
      <hr />
      <div class="button-content">
        <b-upload v-model="file" @input="uploadFile">
          <a class="button is-primary">
            <b-icon icon="upload"></b-icon>
          </a>
        </b-upload>
      </div>
      <div v-if="showFiles.length === 0" class="no-files">
        <p class="title">Sin archivos</p>
      </div>
      <div v-else>
        <b-table :data="showFiles">
          <b-table-column :label="i18n.fileName[iso]" v-slot="props" centered>
            {{ props.row.Nombre }}
          </b-table-column>
          <b-table-column :label="i18n.fileformat[iso]" v-slot="props" centered>
            {{ props.row.Formato }}
          </b-table-column>
          <b-table-column :label="i18n.user[iso]" v-slot="props" centered>
            {{ props.row.CreadoPor }}
          </b-table-column>
          <b-table-column :label="i18n.dateUpload[iso]" v-slot="props" centered>
            {{ parseDate(props.row.FechaCreacion) }}
          </b-table-column>
          <b-table-column :label="i18n.actions[iso]" v-slot="props" centered>
            <b-button
              type="is-danger"
              class="mr-2"
              @click="deleteFile(props.row.Id)"
            >
              <b-icon icon="delete"></b-icon>
            </b-button>
            <b-button
              type="is-info"
              @click="downloadFile(props.row.Id, props.row.Nombre)"
            >
              <i class="fas fa-download"></i>
            </b-button>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div> -->
</template>

<script>
import i18n from "../../utils/i18n";
import moment from "moment";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "ContractMember",
  props: {
    contract: {
      type: Object,
      required: true,
    },
    templateDocuments: {
      type: Array,
      required: true,
    },
    club: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Table
       */
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      loadingReserves: false,
      loadingGenerateDocument: null,
      loadingUploadDocument: null,
      loadingRemoveDocument: null,
      loadingDownloadDocument: null,

      /**
       * Table
       */
      i18n,
      user: null,
      iso: null,
      file: null,
      showFiles: [],
      data: null,
      processedFiles: [],
      selectedFiles: [],
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.user = localStorage.getItem("user");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    // this.templateDocuments = this.contract?.ClubId?.Plantillas || [];
  },
  async mounted() {
    await this.getFiles();
  },
  methods: {
    async uploadFile(ref) {
      try {
        this.loadingUploadDocument = ref;

        const formData = new FormData();
        formData.append("pdf", this.file);

        const headers = { "Content-Type": "multipart/form-data" };

        await axios.post(
          `${API_URL}/file-contract/${this.contract.Id}/${ref}?user=${this.user}`,
          formData,
          headers
        );
        this.file = null;

        this.$buefy.toast.open({
          message: this.i18n.documentUploadSuccessfully[this.iso],
          type: "is-success",
        });

        this.loadingUploadDocument = null;
        await this.$emit("refreshProfile");
      } catch (err) {
        console.log(err);
        this.loadingUploadDocument = null;
        this.$buefy.toast.open({
          message: this.i18n.documentUploadError[this.iso],
          type: "is-danger",
        });
      }
    },
    async downloadFile(ref, fileId, filename) {
      try {
        this.loadingDownloadDocument = ref;

        const { data } = await axios.get(`${API_URL}/file-contract/${fileId}`, {
          responseType: "blob",
        });
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // link.remove();
        this.loadingDownloadDocument = null;
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error",
          type: "is-danger",
        });
      }
    },
    async deleteFile(fileId) {
      try {
        await axios.delete(`${API_URL}/file-contract/${fileId}`);

        this.$buefy.toast.open({
          message: "Se Eliminó el Archivo",
          type: "is-success",
        });
        await this.getFiles();
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al Eliminar Archivo",
          type: "is-danger",
        });
      }
    },
    async getFiles() {
      try {
        const { data } = await axios.get(
          `${API_URL}/file-contract/list/${this.contract.Id}`
        );
        this.showFiles = data.files;
        console.log(data.files);
      } catch (err) {
        console.log(err);
      }
    },
    parseDate(value) {
      moment.locale(this.iso);
      return value ? moment(value).utc().format("LL") : "--";
    },
    async downloadPdfOwners(type) {
      try {
        //Loadings
        this.loadingGenerateDocument = type;

        const { data } = await axios.get(
          `${API_URL}/catcher/pdf-download/owners?type=${type}&contractId=${this.contract.Id}`
        );

        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(data.pdf.data).buffer])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Document_${Date.now()}.pdf`);
        document.body.appendChild(link);
        link.click();

        //Loadings
        this.loadingGenerateDocument = null;

        this.$buefy.dialog.alert({
          message: this.i18n.documentGeneratedSuccessfully[this.iso],
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } catch (err) {
        console.log(err);
        this.templLoading = null;
        this.$buefy.dialog.alert({
          message: this.i18n.documentGeneratedError[this.iso],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async removeDocument(ref, fileId) {
      try {
        this.loadingRemoveDocument = ref;

        await axios.delete(`${API_URL}/file-contract/${fileId}`);

        this.$buefy.toast.open({
          message: this.i18n.documentRemovedSuccessfully[this.iso],
          type: "is-success",
        });

        this.loadingRemoveDocument = null;
        await this.$emit("refreshProfile");
      } catch (err) {
        console.log(err);
        this.loadingRemoveDocument = null;
        this.$buefy.toast.open({
          message: this.i18n.documentRemovedError[this.iso],
          type: "is-danger",
        });
      }
    },
    parseLongText(text) {
      if (!text) return "";
      const textSplit = text.split("");
      let acum = 0;
      let newText = [];
      for (let i in textSplit) {
        if (acum < 24) {
          newText.push(textSplit[i]);
          acum += 1;
        } else {
          newText.push(" ");
          newText.push(textSplit[i]);
          acum = 0;
        }
      }
      return newText.join("");
    },
  },
};
</script>

<style scoped>
.custom-button {
  flex: 1;
  margin-right: 10px;
  width: 300px;
  font-size: 1rem;
}
.button-row {
  display: flex;
  margin-right: 10px;
}
.button-content {
  display: flex;
}
.culumn-content {
  justify-content: center;
}
.vertical-line {
  border-left: 1px solid lightgray;
  height: 520px;
  margin: 0 20px;
}
.no-files {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: gray;
}
</style>
