import Vue from "vue";
import VueRouter from "vue-router";

/**
 * Capturador
 */
import NavbarCap from "../components/Capturador/NavbarCap.vue";
import NewMemberCap from "../views/Capturador/NewMemberCap.vue";
import ListMembers from "../views/Capturador/ListMembers.vue";
import ProfileMemberCap from "../views/Capturador/ProfileMemberCap.vue";
import LoginCap from "../views/Capturador/LoginCap.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    redirect: "/logincap",
    meta: {
      isAuth: false,
    },
  },
  {
    path: "/logincap",
    name: "LoginCap",
    component: LoginCap,
    meta: {
      isAuth: true,
    },
  },
  {
    path: "/navbar-cap",
    name: "navbar-cap",
    component: NavbarCap,
    children: [
      {
        path: "/new-member",
        name: "NewMemberCap",
        component: NewMemberCap,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/list-members",
        name: "ListMembers",
        component: ListMembers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profile-member",
        name: "ProfileMemberCap",
        component: ProfileMemberCap,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("user")) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.isAuth)) {
    if (localStorage.getItem("user")) {
      next("/new-member");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
