<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.roles[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <div class="columns">
      <div class="column is-2"></div>
    </div>
    <TableList
      :data="systemRoles"
      :columns="columns"
      :loading="loadingTableList"
      :actions="actions"
    />

    <!-- Modals -->
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";
import TableList from "../../components/TableList.vue";

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,

      //Loading
      loadingTableList: false,
      loadingButton: false,

      //Data
      systemRoles: [],

      //Modals
      ActiveModalCreatePaymentReservation: false,

      //Columns
      columns: [
        {
          field: "Nombre",
          label: "Nombre",
        },
        {
          field: "Permisos",
          label: "Permisos",
        },
        {
          field: "CreadoPor",
          label: "CreadoPor",
        },
      ],

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      actions: {
        label: "Acciones",
        items: [
          // {
          //   icon: "copy",
          //   color: "is-info",
          //   exec: (item) => {
          //     if (item.Dominio) {
          //       navigator.clipboard.writeText(`${item.Dominio}/${item.Id}`);
          //       this.$buefy.toast.open({
          //         message: this.i18n.linkCopy__msg1[this.iso],
          //         type: "is-info",
          //       });
          //     }
          //   },
          // },
          // {
          //   icon: "paper-plane",
          //   color: "is-success",
          //   exec: (item) => this.sendEmailPaymentLink(item.Id),
          // },
        ],
      },
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //Permissions
    this.permissions = localStorage.getItem("role");

    //
    this.listRoles();
  },
  components: {
    TableList,
  },
  methods: {
    async listRoles() {
      try {
        this.loadingTableList = true;
        const { data } = await axios.post(`${API_URL}/system/list-roles`, {});
        this.systemRoles = data?.roles || [];
        this.loadingTableList = false;
      } catch (err) {
        this.loadingTableList = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar roles",
          type: "is-danger",
        });
      }
    },
  },
};
</script>
