<template>
  <div class="card" style="width: 800px; height: auto">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ i18n.editMember[iso] }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-field :label="i18n.name[iso]" :label-position="labelPosition">
              <b-input v-model="member.name" maxlength="30"></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :label="i18n.lastname[iso]"
              :label-position="labelPosition"
            >
              <b-input v-model="member.lastname" maxlength="30"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field
              :label="i18n.codCountry[iso]"
              :label-position="labelPosition"
            >
              <b-select v-model="member.codPhone" expanded>
                <option v-for="cc in codCountries" :value="cc.dial_code">
                  {{ `${cc.name} (${cc.dial_code})` }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field :label="i18n.phone[iso]" :label-position="labelPosition">
              <b-input v-model="member.phone" maxlength="30"></b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              :label="i18n.language[iso]"
              :label-position="labelPosition"
            >
              <b-select v-model="member.iso" expanded>
                <option value="es">
                  {{ i18n.spanish[iso] }}
                </option>
                <option value="en">
                  {{ i18n.english[iso] }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        style="height: auto"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        @click="editMember"
        :class="`card-footer-item button is-success is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        ><b>{{ i18n.edit[iso] }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
const XmlBeautify = require("xml-beautify");
const { DOMParser } = require("xmldom");
import i18n from "../utils/i18n";
import codCountries from "../utils/codeCountries";

export default {
  name: "VacancyBackofficeModalLogs",
  props: ["__member"],
  data() {
    return {
      labelPosition: "on-border",
      isOpen: true,
      logs: [],
      codCountries,
      member: null,
      loading: false,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //Parse data
    let codPhone = null;
    if (this.__member.codPhone) {
      if (this.__member.codPhone.indexOf("+") < 0)
        codPhone = `+${this.__member.codPhone}`;
      else codPhone = this.__member.codPhone;
    }
    this.member = {
      ...this.__member,
      iso: this.__member.iso.toString().toLowerCase() || null,
      codPhone: codPhone,
    };
    console.log(this.member);
  },
  mounted() {},

  methods: {
    async editMember() {
      try {
        this.loading = true;
        await axios.put(`${API_URL}/catcher/members/edit/${this.member._id}`, {
          ...this.member,
        });
        this.loading = false;
        this.$buefy.toast.open({
          message: "Socio/Miembro actualizado con éxito",
          type: "is-success",
        });
        this.$emit("refresh");
        this.$emit("close");
      } catch (error) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al actualizar socio/miembro",
          type: "is-danger",
        });
      }
    },
  },
};
</script>
