<template>
  <div class="card" style="width: 800px; height: auto">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ i18n.createNewPayment[iso] }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-4">
            <b-field :label="i18n.code[iso]" :label-position="labelPosition">
              <b-input v-model="payment.cod" maxlength="10"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field :label="i18n.name[iso]" :label-position="labelPosition">
              <b-input v-model="payment.name" maxlength="30"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field
              :label="i18n.description[iso]"
              :label-position="labelPosition"
            >
              <b-input v-model="payment.description" maxlength="60"></b-input>
            </b-field>
          </div>
          <hr />
          <div class="column is-6">
            <b-field
              :label="i18n.publicToken[iso]"
              :label-position="labelPosition"
            >
              <b-input
                type="password"
                password-reveal
                v-model="payment.tokenPublic"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :label="`${i18n.publicToken[iso]} - ${i18n.test[iso]}`"
              :label-position="labelPosition"
            >
              <b-input
                type="password"
                password-reveal
                v-model="payment.tokenPublicTest"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :label="i18n.privateToken[iso]"
              :label-position="labelPosition"
            >
              <b-input
                type="password"
                password-reveal
                v-model="payment.tokenPrivate"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :label="`${i18n.privateToken[iso]} - ${i18n.test[iso]}`"
              :label-position="labelPosition"
            >
              <b-input
                type="password"
                password-reveal
                v-model="payment.tokenPrivateTest"
              ></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        style="height: auto"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        :class="`card-footer-item button is-success is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        @click="create"
        ><b>{{ i18n.save[iso] }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../utils/i18n";

export default {
  name: "VacancyBackofficeModalLogs",
  props: ["__member"],
  data() {
    return {
      labelPosition: "on-border",
      isOpen: true,
      logs: [],
      member: null,
      loading: false,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,

      //
      payment: {
        cod: null,
        name: null,
        description: null,
        tokenPrivate: null,
        tokenPrivateTest: null,
        tokenPublic: null,
        tokenPublicTest: null,
      },
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  mounted() {},

  methods: {
    async create() {
      try {
        this.loading = true;
        await axios.post(`${API_URL}/config/payment/create`, {
          ...this.payment,
        });
        this.loading = false;
        this.$emit("refresh");
        this.$emit("close");
      } catch (err) {
        this.loading = false;
        console.log(err);
        let msg = "Error al crear método de pago";
        if (err?.response?.data?.error) msg = err?.response?.data?.error;
        this.$buefy.toast.open({
          message: msg,
          type: "is-danger",
        });
      }
    },
  },
};
</script>
