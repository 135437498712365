<template>
  <div>
    <HeaderPage :title="title" icon="fa-plane-departure" />
    <div class="my-container">
      <Filters :filters="filterItems" @applyFilters="applyFilters" />
      <div class="my-hr"></div>
      <div class="my-buttons">
        <b-button
          type="is-primary"
          @click="isModalActive = true"
          icon-left="plus"
          icon-pack="fas"
          >Crear Reservación Manual</b-button
        >
      </div>
      <div class="my-hr"></div>
      <TableList
        :data="bookingsList"
        :columns="columns"
        :perPage="perPage"
        :loading="loadingBookings"
        :currentPage="currentPage"
        :paginate="pagination"
        @returnPage="changeLimitPage"
        @returnCurrentPage="changeCurrentPage"
        :actions="actions"
        size="small"
      />
    </div>
    <GenerateReservationModal
      :isActive.sync="isModalActive"
      @submit="createReservation"
    />
  </div>
</template>

<script>
import HeaderPage from "@/components/HeaderPage.vue";
import Filters from "@/components/FiltersComponent.vue";
import TableList from "@/components/TableList.vue";
import GenerateReservationModal from "@/views/Bookings/GenerateReservationModal.vue";
import axios from "axios";
import moment from "moment";

const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "BookingsList",
  components: {
    HeaderPage,
    Filters,
    TableList,
    GenerateReservationModal,
  },
  data() {
    return {
      isModalActive: false,
      title: "Bookings",
      perPage: 10,
      currentPage: 1,
      pagination: {},
      filterItems: [
        {
          label: "Creation Date",
          type: "b-datepicker",
          model: "dateRange",
          props: { placeholder: "DD/MM/YYYY", range: true },
        },
        {
          label: "TC Reference",
          type: "b-input",
          model: "ref",
          props: { type: "text" },
        },
      ],
      columns: [
        { field: "ReferenciaReserva", label: "ID Ref", type: "text" },
        { field: "NombreHotel", label: "Hotel", type: "text" },
        { field: "Checkin", label: "Check-in", type: "date" },
        { field: "Checkout", label: "Chek-out", type: "date" },
        {
          field: "FechaCreacion",
          label: "Creation Date",
          type: "date",
        },
        { field: "Estado", label: "Estatus", type: "text" },
        { field: "Monto", label: "Total", type: "amount" },
      ],
      loadingBookings: false,
      bookingsList: [],
      filters: {
        dateRange: [],
        ref: "",
      },

      actions: {
        label: "Acciones",
        items: [
          {
            icon: "link",
            color: "is-success",
            exec: (item) => this.sendPaymentLink(item),
          },
          {
            icon: "dollar-sign",
            color: "is-link",
            exec: (item) => this.addPayment(item),
          },
        ],
      },
    };
  },
  mounted() {
    this.getBookings();
  },
  methods: {
    getBookings() {
      axios
        .get(`${API_URL}/reservations/list`)
        .then(({ data }) => {
          console.log(data);
          this.bookingsList = data;
          this.pagination = data.pagination;
        })
        .catch((err) => {
          this.$buefy.toast.open({
            message: "Error fetching bookings",
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loadingBookings = false;
        });
    },
    applyFilters(newFilters) {
      this.filters = newFilters;
      this.getBookings();
    },
    changeLimitPage(limit) {
      this.perPage = limit;
      this.currentPage = 1;
      this.getBookings();
    },
    changeCurrentPage(page) {
      this.currentPage = page;
      this.getBookings();
    },
    addPayment(item) {
      console.log("Payment added", item);
    },
    sendPaymentLink(item) {
      console.log("PaymentLink Sended", item);
    },
    createReservation(reservation) {
      axios
        .post(`${API_URL}/reservations`, reservation)
        .then(() => {
          this.$buefy.toast.open({
            message: "Reservación creada exitosamente",
            type: "is-success",
          });
          this.isModalActive = false;
          this.getBookings();
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: "Error al crear la reservación",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<style scoped>
.my-container {
  padding: 20px;
}

.my-hr {
  border: 0;
  border-top: 1px solid #dbdbdb;
  width: 100%;
}

.my-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}
</style>
