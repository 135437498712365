<template>
  <div>
    <section class="hero header-content">
      <div class="columns">
        <div class="column is-1">
          <b-button
            icon-left="arrow-left"
            icon-pack="fas"
            type="is-info is-light"
            @click="$router.go(-1)"
            expanded
          >
            {{ i18n.goBack[iso] }}
          </b-button>
        </div>
        <div class="column">
          <p class="title">{{ i18n.renewalPeymentLink[iso] }}</p>
        </div>
      </div>
    </section>

    <section>
      <div class="paymant-content">
        <p class="subtitle">{{ i18n.paymantDetails[iso] }}</p>
      </div>

      <div class="columns" style="width: 650px">
        <div class="column">
          <div
            v-for="(item, index) in tableData"
            :key="index"
            v-if="shouldDisplayItem(item)"
            style="margin-bottom: 6px"
          >
            <p
              v-if="shouldDisplayTitle(item)"
              class="subtitle has-text-weight-semibold"
            >
              {{ item.title }}
              {{ i18n[item.fieldIso][iso] }}
            </p>
          </div>
        </div>
        <div class="column">
          <div
            v-for="(item, index) in tableData"
            :key="index"
            v-if="shouldDisplayItem(item)"
            style="margin-bottom: 6px"
          >
            <template v-if="item.key === 'pay'">
              <b-tag
                :type="getDetailValue(item.key) ? 'is-success' : 'is-danger'"
              >
                <p>{{ getDetailValue(item.key) ? "Pagado" : "No Pagado" }}</p>
              </b-tag>
            </template>
            <template
              v-else-if="
                item.key === 'FechaEmision' || item.key === 'FechaVencimiento'
              "
            >
              <p class="subtitle">{{ parseDate(getDetailValue(item.key)) }}</p>
            </template>
            <template v-else-if="item.key === 'Cargo'">
              <p class="subtitle">${{ getDetailValue(item.key) }}</p>
            </template>
            <template v-else>
              <p class="subtitle">
                {{ getDetailValue(item.key) || getStaticValue(item.key) }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from "../../utils/i18n";
import moment from "moment";

export default {
  data() {
    return {
      i18n,
      iso: null,
      renovaciones: {},
      loginID: "",
      nombre: "",
      apellidoPaterno: "",
      email: "",
      usuario: "Angel",
      details: [],
      tableData: [
        { fieldIso: "LoginID", key: "loginID" },
        { fieldIso: "amount", key: "Cargo" },
        { fieldIso: "state", key: "pay" },
        { fieldIso: "createdBy", key: "CreadoPor" },
        {
          fieldIso: "emitDate",
          key: "FechaEmision",
        },
        {
          fieldIso: "expiredAt",
          key: "FechaVencimiento",
        },
        {
          fieldIso: "cardHolder",
          key: "nombreCompleto",
        },
        { fieldIso: "email", key: "email" },
        {
          fieldIso: "stripePaymentIntentID",
          key: "StripePaymentIntentID",
        },
        {
          fieldIso: "stripeChangeID",
          key: "StripeChangeID",
        },
        {
          fieldIso: "paymentMethod",
          key: "MetodoDePago",
        },
        { fieldIso: "ipAddress", key: "DireccionIP" },
      ],
    };
  },
  methods: {
    getDetailValue(key) {
      if (
        this.details &&
        (key === "FechaEmision" || key === "FechaVencimiento" || key === "pay")
      ) {
        const detail = this.details.find((item) => item[key]);
        return detail ? detail[key] : "";
      }
      if (key === "Cargo") {
        return this.renovaciones[key] || "";
      }
      return "";
    },
    getStaticValue(key) {
      if (key === "loginID") return this.loginID;
      if (key === "email") return this.email;
      if (key === "nombreCompleto")
        return `${this.nombre} ${this.apellidoPaterno}`;
      if (this.renovaciones) return this.renovaciones[key] || "-";
      return "-";
    },
    parseDate(value) {
      moment.locale(this.iso);
      return value ? moment(value).format("MMMM D, YYYY") : "-";
    },
    shouldDisplayItem(item) {
      if (this.usuario === "Online") {
        return true;
      } else {
        return (
          item.key !== "StripePaymentIntentID" &&
          item.key !== "StripeChangeID" &&
          item.key !== "MetodoDePago" &&
          item.key !== "DireccionIP"
        );
      }
    },
    shouldDisplayTitle(item) {
      return this.shouldDisplayItem(item);
    },
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.renovaciones = this.$route.query.id || {};
    this.loginID = this.$route.query.loginID || "";
    this.nombre = this.$route.query.nombre || "";
    this.apellidoPaterno = this.$route.query.apellidoPaterno || "";
    this.email = this.$route.query.email || "";
    this.details = this.renovaciones.details || [];

    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
};
</script>

<style>
.header-content {
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.paymant-content {
  margin-bottom: 2rem;
}
</style>
