<template>
  <div class="container">
    <div class="mt-3">
      <p class="title">{{ i18n.email[iso] }}</p>
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="card-content tarjeta" @click="sendEmailStatusMembership">
            <div class="mr-3">
              <i class="fa fa-envelope fa-2x"></i>
            </div>
            <div v-if="loadingCard === 'membership'">
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.loading[iso] }}
              </h3>
              <p></p>
            </div>
            <div v-else>
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.sendWelcomeEmail[iso] }}
              </h3>
              <p>{{ i18n.descSendWelcmomeEmail[iso] }}</p>
            </div>
          </div>
        </div>

        <div class="column is-4">
          <div class="card-content tarjeta" @click="recoveryPassword">
            <div class="mr-3">
              <i class="fa fa-envelope fa-2x"></i>
            </div>
            <div v-if="loadingCard === 'password'">
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.loading[iso] }}
              </h3>
              <p></p>
            </div>
            <div v-else>
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.sendPasswordRecoveryEmail[iso] }}
              </h3>
              <p>{{ i18n.descPasswordRecoveryEmail[iso] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../utils/i18n";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "ActionsMember",
  props: {
    contract: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      i18n,
      iso: null,
      isLoading: false,
      loadingCard: null,
    };
  },
  methods: {
    async sendEmailStatusMembership() {
      this.loadingCard = "membership";
      try {
        await axios.put(
          `${API_URL}/catcher/edit-status-membership/contract/${
            this.contract.Id
          }?forceTrue=y&updatedBy=${localStorage.getItem("user")}`
        );
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.$bus.emit('refresh-comments')
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al enviar email",
          type: "is-danger",
        });
      } finally {
        this.loadingCard = null;
      }
    },
    async recoveryPassword() {
      this.loadingCard = "password";
      try {
        await axios.get(
          `${API_URL}/recovery-password/members/send?email=${this.email}`
        );
      } catch (err) {
        this.$buefy.toast.open({
          message: "Error al enviar email",
          type: "is-danger",
        });
      } finally {
        this.loadingCard = null;
      }
    },
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  async mounted() {
    console.log(this.contract);
  },
};
</script>

<style scoped>
.tarjeta {
  background-color: rgb(234, 236, 240);
  display: flex;
  height: 165px;
  width: 430px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tarjeta:hover {
  background-color: #48c774;
}
</style>
