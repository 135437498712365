import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Sidebar from "../components/Sidebar.vue";
import Week from "../views/Week.vue";
import Reserve from "../views/Reserve.vue";
import ReserveDetails from "../views/ReserveDetails.vue";
import CatcherMembers from "../views/CatcherMembers";
import SettingPayment from "../views/SettingPayment";
import Users from "../views/System/Users.vue";
import Roles from "../views/System/Roles.vue";
import AssignmentActivation from "../views/Activations/AssignmentActivation.vue";
import NotFound from "../views/404.vue";
import MassRenewals from "../views/MassRenewals";
import RenewalsFollowUp from "../views/RenewalsFollowUp";

/* Paymentlinks */
import PaymentsLinks from "../views/Payments/PaymentsLinksView.vue";
import PaymentsLinksList from "../views/Payments/PaymentsLinksList.vue";
import PaymentLinkDetail from "../views/Payments/PaymentLinkDetail.vue";

/* Customer portfolio */
import CustomerPortfolioView from "../views/CustomerPortfolio/CustomerPortfolioView.vue";
import Statistics from "../views/Renewals/Statistics.vue";
import DetailsRenewals from "../views/Renewals/DetailsRenewals.vue";

/**
 * Membership
 */
import MemberProfile from "../views/Membership/MemberProfile";

/**
 * Permissions
 */
import { checkPermission } from "@/utils/permissions";

/**
 * Capturador
 */
import NavbarCap from "../components/Capturador/NavbarCap.vue";
import NewMemberCap from "../views/Capturador/NewMemberCap.vue";
import ListMembers from "../views/Capturador/ListMembers.vue";
import ProfileMemberCap from "../views/Capturador/ProfileMemberCap.vue";
import LoginCap from "../views/Capturador/LoginCap.vue";
import PaymentsLinksView from "../views/Payments/PaymentsLinksView.vue";

/* Catalogos */
import DiscountCodes from "@/views/CodigosDescuento/IndexView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      isAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Sidebar,
    children: [
      {
        path: "/week",
        name: "week",
        component: Week,
        meta: {
          requiresAuth: true,
          permission: "list-weeks",
        },
      },
      {
        path: "/statistics",
        name: "statistics",
        component: Statistics,
        meta: {
          requiresAuth: true,
          permission: "renewals-statistics",
        },
      },
      {
        path: "/reserve",
        name: "reserve",
        component: Reserve,
        meta: {
          requiresAuth: true,
          permission: "list-bookings",
        },
      },
      {
        path: "/reserve-details",
        name: "ReserveDetails",
        component: ReserveDetails,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/catcher-members",
        name: "catcherMembers",
        component: CatcherMembers,
        meta: {
          requiresAuth: true,
          permission: "list-members",
        },
      },
      {
        path: "/online-renewals",
        name: "onlineRenewals",
        component: MassRenewals,
        meta: {
          requiresAuth: true,
          permission: "renewals-online",
        },
      },
      {
        path: "/discount-codes",
        name: "DiscountCodes",
        component: DiscountCodes,
        meta: {
          requiresAuth: true,
          permission: "list-discount-codes",
        },
      },
      {
        path: "/customer-portfolio",
        name: "customerPortfolio",
        component: CustomerPortfolioView,
        meta: {
          requiresAuth: true,
          permission: "renewals-customer-portfolio",
        },
      },
      {
        path: "/bookings",
        name: "bookings",
        component: require("../views/Bookings/BookingsList.vue").default,
        meta: {
          requiresAuth: true,
          permission: "renewals-customer-portfolio",
        },
      },
      {
        path: "/renewals-follow-up",
        name: "renewalsFollowUp",
        component: RenewalsFollowUp,
        meta: {
          requiresAuth: true,
          permission: "renewals-follow-up",
        },
      },
      {
        path: "/setting-payment",
        name: "SettingPayment",
        component: SettingPayment,
        meta: {
          requiresAuth: true,
          permission: "list-setting-payments",
        },
      },
      {
        path: "/membership-profile",
        name: "membershipProfile",
        component: MemberProfile,
        meta: {
          requiresAuth: true,
          permission: "show-profile-members",
        },
      },
      {
        path: "/details-renewals",
        name: "detailsRenewals",
        component: DetailsRenewals,
        meta: {
          requiresAuth: false,
        },
      },
      // {
      //   path: "/payments-links",
      //   name: "paymentsLinks",
      //   component: PaymentsLinks,
      //   meta: {
      //     requiresAuth: true,
      //     permission: "list-paymentslinks",
      //   },
      // },
      {
        path: "/system-users",
        name: "systemUsers",
        component: Users,
        meta: {
          requiresAuth: true,
          permission: "list-system-users",
        },
      },
      {
        path: "/system-roles",
        name: "systemRoles",
        component: Roles,
        meta: {
          requiresAuth: true,
          permission: "list-system-roles",
        },
      },
      {
        path: "/activations",
        name: "activations",
        component: AssignmentActivation,
        meta: {
          requiresAuth: true,
          permission: "list-members-activations",
        },
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/payments-links",
        name: "paymentsLinks",
        component: PaymentsLinksList,
        meta: {
          requiresAuth: true,
          permission: "list-paymentslinks",
        },
        /* children: [
          {
            path: "list",
            name: "listPayments",
            component: PaymentsLinksList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id",
            name: "paymentLinkDetail",
            component: PaymentLinkDetail,
            meta: {
              requiresAuth: true,
            },
          },
        ], */
      },
      {
        path: "/payments-links-details",
        name: "paymentsLinksDetails",
        component: PaymentLinkDetail,
        meta: {
          requiresAuth: true,
          permission: "details-paymentslinks",
        },
      },
    ],
  },
  {
    path: "/logincap",
    name: "LoginCap",
    component: LoginCap,
    meta: {
      isAuth: false,
    },
  },
  {
    path: "/navbar-cap",
    name: "navbar-cap",
    component: NavbarCap,
    children: [
      {
        path: "/new-member",
        name: "NewMemberCap",
        component: NewMemberCap,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/list-members",
        name: "ListMembers",
        component: ListMembers,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/profile-member",
        name: "ProfileMemberCap",
        component: ProfileMemberCap,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (checkPermission(to.meta.permission)) {
      next();
      return;
    }
    next("/404");
    return;
  } else {
    next();
    return;
  }

  if (to.matched.some((record) => record.meta.isAuth)) {
    if (localStorage.getItem("user")) {
      next("/week");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
