<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container has-text-centered">
          <div class="columns is-vcentered">
            <div class="column is-5">
              <img src="img/error-404.png" alt="404" />
            </div>
            <div class="column is-6 is-offset-1">
              <h1 class="title is-2">{{ i18n.notFound404[iso] }}</h1>
              <h2 class="subtitle is-4">
                {{ i18n.notFound404__msg1[iso] }}
              </h2>
              <br />
              <!-- <p class="has-text-centered">
                <a class="button is-medium is-info is-outlined"> Learn more </a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from "../utils/i18n";

export default {
  data() {
    return {
      i18n,
      iso: null,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
};
</script>
