<template>
  <div>
    <b-tabs>
      <b-tab-item>
        <template #header>
          <b-icon icon="filter" pack="fas" type="is-info"></b-icon>
          <b>{{ i18n.advancedSearch[iso] }}</b>
        </template>
        <div class="advance-search" v-if="membersCatcher">
          <b-field label="Login ID" label-position="on-border">
            <b-input
              v-model="advancedFilters.LoginID"
              type="text"
              value=""
            ></b-input>
          </b-field>
          <b-field :label="i18n.contract[iso]" label-position="on-border">
            <b-input
              v-model="advancedFilters.nroContract"
              type="text"
              value=""
            ></b-input>
          </b-field>
          <b-field :label="i18n.name[iso]" label-position="on-border">
            <b-input
              v-model="advancedFilters.Nombre"
              type="text"
              value=""
            ></b-input>
          </b-field>
          <b-field :label="i18n.lastname[iso]" label-position="on-border">
            <b-input
              v-model="advancedFilters.ApellidoPaterno"
              type="text"
              value=""
            ></b-input>
          </b-field>
          <b-field :label="i18n.email[iso]" label-position="on-border">
            <b-input
              v-model="advancedFilters.Email"
              type="email"
              value=""
            ></b-input>
          </b-field>
          <b-field :label="i18n.processingDate[iso]" label-position="on-border">
            <b-datepicker
              v-model="advancedFilters.FechaProcesable"
              placeholder="DD/MM/YYYY"
              :min-date="minDate"
              :max-date="maxDate"
              :locale="iso"
              range
            ></b-datepicker>
          </b-field>
          <b-field
            :label="i18n.cancellationDate[iso]"
            label-position="on-border"
          >
            <b-datepicker
              v-model="advancedFilters.FechaCancelado"
              placeholder="DD/MM/YYYY"
              :min-date="minDate"
              :max-date="maxDate"
              :locale="iso"
              range
            ></b-datepicker>
          </b-field>
          <b-field :label="i18n.renovationDate[iso]" label-position="on-border">
            <b-datepicker
              v-model="advancedFilters.FechaRenovacion"
              placeholder="DD/MM/YYYY"
              :min-date="minDate"
              :max-date="maxDate"
              :locale="iso"
              range
            ></b-datepicker>
          </b-field>
          <b-field label="Club" label-position="on-border">
            <b-select v-model="advancedFilters.club" expanded>
              <option :value="null"></option>
              <option
                v-for="club in clubesName"
                :value="club._id"
                :key="club._id"
              >
                {{ club.Nombre }}
              </option>
            </b-select>
          </b-field>
          <b-field
            :label="i18n.membershipStatus[iso]"
            label-position="on-border"
          >
            <b-select v-model="advancedFilters.status" expanded>
              <option :value="null"></option>
              <option :value="1">{{ i18n.current[iso] }}</option>
              <option :value="0">{{ i18n.expired[iso] }}</option>
            </b-select>
          </b-field>
          <b-field :label="i18n.contractStatus[iso]" label-position="on-border">
            <b-select
              v-model="advancedFilters.contractStatus"
              @input="checkValidateAdvancedFilters"
              expanded
            >
              <option :value="null"></option>
              <option :value="1">{{ i18n.pending[iso] }}</option>
              <option :value="2">{{ i18n.procesable[iso] }}</option>
              <option :value="3">{{ i18n.cancelled[iso] }}</option>
            </b-select>
          </b-field>
          <b-field :label="i18n.languageUser[iso]" label-position="on-border">
            <b-select v-model="advancedFilters.language" expanded>
              <option :value="null"></option>
              <option :value="'SP'">{{ i18n.spanish[iso] }}</option>
              <option :value="'EN'">{{ i18n.english[iso] }}</option>
            </b-select>
          </b-field>
          <div class="filter-buttons">
            <b-button
              icon-left="search"
              icon-pack="fas"
              class="primerito"
              type="is-info"
              @click="advancedSearch(false)"
            >
              {{ i18n.search[iso] }}
            </b-button>
            <b-button
              icon-left="eraser"
              icon-pack="fas"
              type="is-danger"
              @click="clearFilters(false)"
            ></b-button>
          </div>
        </div>
        <div class="columns is-multiline" v-if="!membersCatcher">
          <div class="column is-3">
            <b-field label-position="on-border" label="LoginID">
              <b-input
                v-model="advancedFilters.LoginID"
                type="text"
                icon-right="times"
                icon-right-clickable
                expanded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label-position="on-border" :label="i18n.contract[iso]">
              <b-input
                v-model="advancedFilters.nroContract"
                type="text"
                icon-right="times"
                icon-right-clickable
                expanded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label-position="on-border" :label="i18n.name[iso]">
              <b-input
                v-model="advancedFilters.Nombre"
                type="text"
                icon-right="times"
                icon-right-clickable
                expanded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label-position="on-border" :label="i18n.lastname[iso]">
              <b-input
                v-model="advancedFilters.ApellidoPaterno"
                type="text"
                icon-right="times"
                icon-right-clickable
                expanded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label-position="on-border" :label="i18n.email[iso]">
              <b-input
                v-model="advancedFilters.Email"
                type="text"
                icon-right="times"
                icon-right-clickable
                expanded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label-position="on-border" :label="i18n.agent[iso]">
              <b-select expanded v-model="advancedFilters.staff">
                <option :value="null"></option>
                <option
                  v-for="user in staff"
                  :value="user.user_gen"
                  :key="user._id"
                >
                  {{ user.Nombre }} {{ user.Apellido }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3 my-date">
            <b-field label-position="on-border" :label="i18n.renewalDate[iso]">
              <b-datepicker
                v-model="advancedFilters.FechaRenovacion"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
                :disabled="validateAdvancedFilters.FechaRenovacion"
              ></b-datepicker>
            </b-field>
          </div>
          <div class="column is-2">
            <b-button
              icon-left="search"
              icon-pack="fas"
              type="is-info"
              @click="advancedSearch(false)"
              expanded
              >{{ i18n.search[iso] }}</b-button
            >
          </div>
          <div class="column is-1">
            <b-button
              icon-left="eraser"
              icon-pack="fas"
              type="is-danger"
              @click="clearFilters(false)"
              expanded
            ></b-button>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../utils/i18n";
import moment from "moment";
import Multiselect from "vue-multiselect";

const today = new Date();

export default {
  name: "FilterForm",
  props: {
    advancedFilters: Object,
    filterFields: Array,
    custom: Boolean,
    membersCatcher: Boolean,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      tabFilters: 1,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      loadingClubes: false,
      clubesName: [],
      options: [
        { name: "paid" },
        { name: "pending" },
        { name: "expired" },
        { name: "canceled" },
      ],
      staff: [],
      loadingExportMembers: false,
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesable: false,
        FechaRenovacion: false,
        FechaCancelado: false,
        Language: false,
        status: false,
        Nombre: false,
        ApellidoPaterno: false,
        Email: false,
        LoginID: false,
        nroContract: false,
        staff: false,
        club: false,
        contractStatus: false,
      },
      iso: "es",
      i18n,
      minDate: moment(today).subtract(5, "years").toDate(),
      maxDate: today,
    };
  },
  methods: {
    advancedSearch(val) {
      this.$emit("searchMembers", val);
    },
    checkValidateAdvancedFilters() {
      this.validateAdvancedFilters.contractStatus = false;
      if (this.advancedFilters.contractStatus === 3) {
        this.validateAdvancedFilters.FechaCancelado = true;
      }
    },
    clearFilters(val) {
      this.$emit("clearFilters", val);
    },
    async loadClubes() {
      this.loadingClubes = true;
      try {
        const response = await axios.get(`${API_URL}/clubes`);
        this.clubesName = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingClubes = false;
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    async loadStaff() {
      try {
        const response = await axios.get(`${API_URL}/staff`);
        this.staff = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    membersCatcher: function (newVal) {
      if (newVal) {
        this.loadClubes();
        this.loadStaff();
      }
    },
  },
  created() {
    if (this.membersCatcher) {
      this.loadClubes();
      this.loadStaff();
    }
  },
};
</script>

<style scoped>
.advance-search {
  padding: 1.5rem;
}
.my-date .b-datepicker .datepicker-content .calendar.is-primary {
  display: block;
  max-width: 350px;
  width: 100%;
}
.primerito {
  flex-grow: 1;
}
.filter-buttons {
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
}
</style>
