<template>
  <div>
    <b-field label="Notas Importantes" label-position="on-border">
      <b-input
        v-model="reservation.NotasImportantes"
        maxlength="500"
        type="textarea"
        required
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "ReservationDescription",
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
