<template>
  <section>
    <b-field>
      <b-message title="Asignación de cuentas a Agentes">
        <p>Total de cuentas: {{ members.length - totalAssigned }}</p>
      </b-message>
    </b-field>
    <b-table :data="selectedStaff">
      <b-table-column field="agent.name" label="Nombre" custom-key="agent">
        <template slot-scope="props">
          {{ `${props.row.Nombre} ${props.row.Apellido}` }}
        </template>
      </b-table-column>
      <b-table-column field="agent.name" label="Usuario" custom-key="agent">
        <template slot-scope="props">
          {{ props.row.Usuario }}
        </template>
      </b-table-column>
      <b-table-column label="Asignados">
        <template slot-scope="props">
          {{ props.row.assignmentsCount }}
        </template>
      </b-table-column>
      <b-table-column label="Miembros a Asignar">
        <template slot-scope="props">
          <b-input
            type="number"
            v-model.number="assignedMembers[props.row._id]"
            @input="updateAssignments"
          />
        </template>
      </b-table-column>
    </b-table>
    <b-button type="is-primary" @click="finalizeAssignments"
      >Finalizar Asignaciones</b-button
    >
  </section>
</template>

<script>
export default {
  name: "AssignTable",
  props: {
    selectedStaff: {
      type: Array,
      required: true,
    },
    members: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      assignedMembers: {},
    };
  },
  computed: {
    totalAssigned() {
      return Object.values(this.assignedMembers).reduce(
        (sum, val) => sum + (val || 0),
        0
      );
    },
  },
  methods: {
    updateAssignments() {
      if (this.totalAssigned > this.members.length) {
        let excess = this.totalAssigned - this.members.length;
        for (let agentId in this.assignedMembers) {
          if (this.assignedMembers[agentId] > 0) {
            let reduction = Math.min(this.assignedMembers[agentId], excess);
            this.assignedMembers[agentId] -= reduction;
            excess -= reduction;
            if (excess <= 0) break;
          }
        }
      }
    },
    finalizeAssignments() {
      let result = [];
      let remainingMembers = [...this.members];

      for (let staff of this.selectedStaff) {
        let agentId = staff._id;
        let count = this.assignedMembers[agentId] || 0;
        let assigned = [];

        for (let i = 0; i < count && remainingMembers.length > 0; i++) {
          let randomIndex = Math.floor(Math.random() * remainingMembers.length);
          assigned.push(remainingMembers.splice(randomIndex, 1)[0]);
        }

        result.push({ agent: agentId, clients: assigned });
      }

      this.$emit("assignmentsCompleted", result);
    },
  },
};
</script>

<style scoped>
section {
  padding: 20px;
}
</style>
