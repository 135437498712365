<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.settingsPayments[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <div class="columns">
        <div class="column is-4">
          <b-button
            icon-left="money-bill"
            icon-pack="fas"
            type="is-info is-light"
            @click="openModal"
            >{{ i18n.createNewPayment[iso] }}</b-button
          >
        </div>
      </div>
      <hr />
      <b-table
        :data="data"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :bordered="isBordered"
        :striped="false"
        :narrowed="isNarrowed"
        :hoverable="false"
        :loading="loading"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
      >
        <b-table-column
          field="cod"
          :label="i18n.code[iso]"
          centered
          v-slot="props"
        >
          <b-tag type="is-info">{{ props.row.cod }}</b-tag>
        </b-table-column>

        <b-table-column
          field="name"
          :label="i18n.name[iso]"
          centered
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="description"
          :label="i18n.description[iso]"
          centered
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column
          field="currency"
          :label="i18n.currency[iso]"
          centered
          v-slot="props"
        >
          <b-icon
            pack="fas"
            icon="dollar-sign"
            size="is-small"
            type="is-success"
            v-if="props.row.currency === 'usd'"
          >
          </b-icon>
          {{ props.row.currency }}
        </b-table-column>

        <b-table-column
          field="isDefault"
          :label="i18n.main[iso]"
          centered
          v-slot="props"
        >
          <b-icon
            v-if="props.row.isDefault"
            pack="fas"
            icon="check"
            size="is-small"
            type="is-success"
          >
          </b-icon>
          <b-icon
            v-else
            pack="fas"
            icon="times"
            size="is-small"
            type="is-danger"
          >
          </b-icon>
        </b-table-column>

        <b-table-column
          field="status"
          :label="i18n.status[iso]"
          centered
          v-slot="props"
        >
          <b-icon
            v-if="props.row.status"
            pack="fas"
            icon="check"
            size="is-small"
            type="is-success"
          >
          </b-icon>
          <b-icon
            v-else
            pack="fas"
            icon="times"
            size="is-small"
            type="is-danger"
          >
          </b-icon>
        </b-table-column>

        <b-table-column label="Actions" v-slot="props" centered width="170">
          <b-tooltip type="is-success">
            <b-button
              icon-left="check-circle"
              icon-pack="fas"
              size="is-small"
              type="is-success"
              style="margin-right: 10px"
              inverted
              :loading="loadingSetPaymentMethod"
              @click="setMethodPayments(props.row._id)"
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.addMainPaymentMethod[iso] }}</b></template
            >
          </b-tooltip>
          <b-tooltip type="is-danger">
            <b-button
              icon-left="trash"
              icon-pack="fas"
              size="is-small"
              type="is-danger"
              inverted
              :loading="loadingDeletePaymentMethod"
              @click="deleteMethodPayments(props.row._id)"
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.deletePaymentMethod[iso] }}</b></template
            >
          </b-tooltip>
        </b-table-column>

        <template #footer>
          <div class="columns">
            <div class="column is-11"></div>

            <div class="column is-1">
              <b-field :label="i18n.quantity[iso]">
                <b-select v-model="perPage" @input="savePerPage" expanded>
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <!-- <div class="has-text-right">Footer</div> -->
        </template>
      </b-table>
    </section>
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-label="Hold"
      aria-modal
    >
      <template #default="props">
        <modal-create-payment
          @close="props.close"
          @refresh="listMethodPayments"
        ></modal-create-payment>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../utils/i18n";
import ModalCreatePayment from "./ModalCreatePayment.vue";

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      data: [],

      //Loadings
      loading: false,
      loadingSetPaymentMethod: false,
      loadingDeletePaymentMethod: false,

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      //Modals
      isComponentModalActive: false,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Data
    this.listMethodPayments();
  },
  components: {
    ModalCreatePayment,
  },
  methods: {
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listAllMembers();
    },
    async listMethodPayments() {
      try {
        this.loading = true;
        const { data } = await axios.post(`${API_URL}/config/payment/list`, {});
        this.data = data.configPayments;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al listas métodos de pagos",
          type: "is-danger",
        });
      }
    },
    openModal() {
      this.isComponentModalActive = true;
    },
    async setMethodPayments(id) {
      this.$buefy.dialog.confirm({
        type: "is-warning",
        hasIcon: true,
        confirmText: this.i18n.yes[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        message: `${this.i18n.textSetPaymentMethod[this.iso]} <b>${
          this.i18n.doYouWishToContinue[this.iso]
        }</b>`,
        onConfirm: async () => {
          try {
            this.loadingSetPaymentMethod = true;
            await axios.put(`${API_URL}/config/payment/set/${id}`);
            this.loadingSetPaymentMethod = false;
            this.listMethodPayments();
            this.$buefy.toast.open({
              message: "Método de pago establecido",
              type: "is-success",
            });
          } catch (err) {
            this.loadingSetPaymentMethod = false;
            console.log(err);
            let msg = "Error al establecer método de pago";
            if (err?.response?.data?.error) msg = err?.response?.data?.error;
            this.$buefy.toast.open({
              message: msg,
              type: "is-danger",
            });
          }
        },
      });
    },
    async deleteMethodPayments(id) {
      this.$buefy.dialog.confirm({
        type: "is-danger",
        hasIcon: true,
        confirmText: this.i18n.yes[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        message: `${this.i18n.textDeletePaymentMethod[this.iso]} <b>${
          this.i18n.doYouWishToContinue[this.iso]
        }</b>`,
        onConfirm: async () => {
          try {
            this.loadingDeletePaymentMethod = true;
            await axios.delete(`${API_URL}/config/payment/delete/${id}`);
            this.loadingDeletePaymentMethod = false;
            this.listMethodPayments();
            this.$buefy.toast.open({
              message: "Método de pago eliminado",
              type: "is-success",
            });
          } catch (err) {
            this.loadingDeletePaymentMethod = false;
            console.log(err);
            let msg = "Error al eliminar método de pago";
            if (err?.response?.data?.error) msg = err?.response?.data?.error;
            this.$buefy.toast.open({
              message: msg,
              type: "is-danger",
            });
          }
        },
      });
    },
  },
};
</script>
