<template>
  <div>
    <b-navbar id="navbar-cap" centered>
      <template #brand>
        <img
          :src="club.Logo"
          alt="Logo"
          style="padding: 5%; margin-left: 30%"
          id="logo"
        />
      </template>
      <template #start>
        <b-navbar-item href="/new-member" active>
          {{ i18n.newMember[iso] }}
        </b-navbar-item>
        <b-navbar-item href="/list-members">
          {{ i18n.members[iso] }}
        </b-navbar-item>
        <b-navbar-dropdown :label="`${club ? club.Nombre : ''}`">
          <b-navbar-item
            v-for="c in clubes"
            @click="changeClub(c)"
            :active="club && club.Id === c.Id ? true : false"
          >
            {{ c.Nombre }}
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <b-tag type=" is-link is-light">v21082024</b-tag>
        </b-navbar-item>
        <b-navbar-item>
          {{ user }}
        </b-navbar-item>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary" @click="changeIso">
              <strong>{{
                iso === "es" ? i18n.spanish[iso] : i18n.english[iso]
              }}</strong>
            </a>
            <a class="button is-light" @click="exit">
              {{ i18n.logout[iso] }}
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <main class="h-full pb-16" style="background-color: #f6f5f2">
      <div class="container px-6 mx-auto grid">
        <router-view
          style="background: white; padding: 30px; margin: 30px"
        ></router-view>
        <!-- box-shadow: 0px 0px 19px 3px rgba(255, 255, 255, 1);
            -webkit-box-shadow: 0px 0px 19px 3px rgba(255, 255, 255, 1);
            -moz-box-shadow: 0px 0px 19px 3px rgba(255, 255, 255, 1); -->
      </div>
    </main>
  </div>
</template>

<script>
import i18n from "../../utils/i18n";

export default {
  async created() {
    let __iso = localStorage.getItem("iso");
    if (!__iso) {
      this.iso = "en";
      localStorage.setItem("iso", "en");
      this.$bus.emit("change-iso");
      this.$forceUpdate();
    } else {
      this.iso = __iso;
    }

    this.user = localStorage.getItem("user") || "";

    // console.log('localStorage.getItem("iso")', localStorage.getItem("iso"))
    // this.iso = localStorage.getItem("iso") || "en";
    // console.log('iso', this.iso)
    // this.$forceUpdate();

    this.getClub();
  },
  data() {
    return {
      iso: "en",
      i18n,
      user: "",
      clubes: [],
      club: null,
    };
  },
  methods: {
    insertIcon(img) {
      let link = null;
      link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = img;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    insertName(name) {
      let text = document.createTextNode(name);
      let title = document.createElement("title");
      title.appendChild(text);
      document.getElementsByTagName("head")[0].appendChild(title);
    },
    changeIso() {
      this.$buefy.dialog.confirm({
        message: this.i18n.changeIso[this.iso],
        type: "is-info",
        onConfirm: () => {
          if (localStorage.getItem("iso") === "es") {
            localStorage.setItem("iso", "en");
            this.iso = "en";
          } else {
            localStorage.setItem("iso", "es");
            this.iso = "es";
          }
          // this.$forceUpdate();
          // this.$buefy.toast.open("User confirmed")
          this.$bus.emit("change-iso");
        },
      });
    },
    exit() {
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userId");
      localStorage.removeItem("clubes");
      localStorage.removeItem("club");
      localStorage.removeItem("permissions");
      this.$router.push("/logincap");
    },
    getClub() {
      let clubes = localStorage.getItem("clubes");
      clubes = clubes ? JSON.parse(clubes) : [];
      this.clubes = clubes;

      let club = localStorage.getItem("club");
      club = club ? JSON.parse(club) : {};
      this.club = club;

      this.insertIcon(club?.LogoIcon || "");
      this.insertName(club?.Nombre || "");

      this.$forceUpdate();
    },
    changeClub(club) {
      localStorage.setItem("club", JSON.stringify(club));
      this.$bus.emit("change-club");
      this.$router.go(0);
    },
  },
};
</script>

<style scoped>
#logo {
  @media (width < 1024px) {
    /* width: 20%; */
    display: none;
  }
}
#navbar-cap {
  box-shadow: -1px 7px 8px -3px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -1px 7px 8px -3px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 7px 8px -3px rgba(0, 0, 0, 0.51);
  height: 80px;
}
</style>
