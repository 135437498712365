<template>
  <div>
    <HeaderPage :title="title" icon="fa-wallet" />
    <section
      class="my-container"
      v-if="fullAccess.includes(role) && !existReassigments"
    >
      <b-tabs v-model="activeTab" position="is-centered" @input="changeTab">
        <b-tab-item>
          <template #header>
            <b-icon icon="lightning-bolt" type="is-danger"></b-icon>
            <b>Asignar</b>
          </template>
          <div>
            <FilterForm
              :advancedFilters="advancedFilters"
              :filterFields="advancedFiltersFields"
              @advancedSearch="advancedSearch"
              @clearFilters="clearFilters"
            />
            <div class="my-hr"></div>
            <div
              class="buttons-group"
              v-if="members.length > 0 || isSelectingStaff"
            >
              <div>
                <b-button
                  icon-left="arrow-left"
                  icon-pack="fas"
                  type="is-primary is-light"
                  @click="goback()"
                  v-if="isSelectingStaff || isAssigning"
                  >Volver</b-button
                >
              </div>
              <div class="my-buttons">
                <b-button
                  type="is-primary"
                  @click="lookForDifferentAgents()"
                  v-if="loaded && isSelectingStaff && typeStaff != 'renewals'"
                >
                  Mostrar agentes de renovaciones
                </b-button>
                <b-button
                  type="is-primary"
                  @click="lookForDifferentAgents()"
                  v-if="loaded && isSelectingStaff && typeStaff != 'recovery'"
                >
                  Mostrar agentes de recuperación
                </b-button>
                <b-button
                  icon-left="check-square"
                  icon-pack="fas"
                  type="is-success"
                  @click="checkAllMembers()"
                  v-if="
                    !isSelectingStaff && !isAssigning && members.length != 0
                  "
                >
                  Marcar todos
                </b-button>
                <b-button
                  icon-left="user-tie"
                  icon-pack="fas"
                  type="is-primary"
                  @click="generateAssign()"
                  v-if="!isSelectingStaff && !isAssigning"
                  :disabled="checkingAll"
                >
                  {{ i18n.renewalAssign[iso] }}
                </b-button>
                <b-button
                  icon-right="arrow-right"
                  icon-pack="fas"
                  type="is-primary"
                  v-if="isSelectingStaff && !isAssigning"
                  @click="divideCustomers()"
                >
                  Continuar
                </b-button>
              </div>
            </div>
            <div class="my-hr"></div>
            <div class="member-checker" v-if="members.length > 0">
              <div class="overlayChecking" v-if="checkingAll">
                <span class="loader"></span>
              </div>
              <TableList
                key="members"
                :data="members"
                type="members"
                :columns="columnsMembers"
                :loading="loadingMembers"
                :perPage="perPage"
                :paginate="paginate"
                :currentPage="currentPage"
                checkable
                :selectedRows="selectedMembers"
                @returnSelecteds="setSelected"
                @returnPage="changeLimitPage"
                @returnCurrentPage="changeCurrentPage"
                v-if="isSelectingMembers"
                class="animate__animated animate__fadeInUp"
              />
            </div>
            <div
              class="search-for-members"
              v-if="members == 0 && !isSelectingStaff && !isAssigning"
            >
              {{ i18n.noMembersFound[iso] }}
            </div>
            <TableList
              key="staff"
              :data="staff"
              :columns="columnsStaff"
              type="staff"
              checkable
              :loading="loadingStaff"
              selectorField="object"
              :selectedRows="selectedStaff"
              @returnSelecteds="setSelected"
              v-if="loaded && isSelectingStaff"
              class="animate__animated animate__fadeInUp"
            />
            <AssignTable
              :selectedStaff="selectedStaff"
              :columns="columnsStaff"
              :members="selectedMembers"
              @assignmentsCompleted="assignmentSave"
              v-if="isAssigning"
            />
          </div>
        </b-tab-item>
        <b-tab-item>
          <template #header>
            <b-icon pack="fas" icon="user-tie" type="is-info"></b-icon>
            <b>Asignaciones</b>
          </template>
          <div>
            <FilterForm
              :advancedFilters="advancedAssignFilters"
              :filterFields="advancedFiltersFields"
              :custom="true"
              checkable
              @advancedSearch="advancedSearch"
              @clearFilters="clearFilters"
            />
            <div class="my-hr"></div>
            <div class="my-buttons">
              <b-button
                icon-right="arrow-right"
                icon-pack="fas"
                type="is-primary"
                :disabled="selectedMembers.length <= 0"
                @click="generateReassign()"
              >
                Reasignar
              </b-button>
            </div>
            <div class="my-hr"></div>
            <!-- <div class="my-buttons">
              <b-button
                icon-left="check-square"
                icon-pack="fas"
                type="is-success"
                @click="checkAllMembers()"
              >
                Marcar todos
              </b-button>
              <b-button
                icon-left="link"
                icon-pack="fas"
                type="is-success"
                @click="sendRenewalLinks()"
                :disabled="selectedAssignments.length <= 0"
              >
                {{ i18n.sendLink[iso] }}
              </b-button>
            </div> -->
            <div class="my-hr"></div>
            <TableList
              key="members-assign"
              type="members"
              :data="assignments"
              :columns="assignmentColumns"
              :loading="loadingAssignments"
              :perPage="perPage"
              :paginate="paginate"
              :actions="role != 'staff' ? {} : actions"
              :currentPage="currentPage"
              :selectedRows="selectedMembers"
              checkable
              selectorField="ContratoId.LoginID"
              selected
              @returnSelecteds="setSelected"
              @returnPage="changeLimitPage"
              @returnCurrentPage="changeCurrentPage"
              class="animate__animated animate__fadeInUp"
            />
          </div>
        </b-tab-item>
      </b-tabs>
    </section>

    <section class="my-container" v-if="role === 'staff'">
      <div class="space"></div>
      <FilterForm
        class="contenedor"
        :advancedFilters="advancedAssignFilters"
        :filterFields="advancedFiltersFields"
        :custom="true"
        checkable
        @advancedSearch="advancedSearch"
        @clearFilters="clearFilters"
      />
      <div class="my-hr"></div>
      <div class="my-buttons">
        <b-button
          icon-left="check-square"
          icon-pack="fas"
          type="is-success"
          @click="checkAllMembers()"
        >
          Marcar todos
        </b-button>
        <b-button
          icon-left="link"
          icon-pack="fas"
          type="is-success"
          @click="sendRenewalLinks()"
          :disabled="selectedAssignments.length <= 0"
        >
          {{ i18n.sendLink[iso] }}
        </b-button>
      </div>
      <div class="my-hr"></div>
      <TableList
        key="assignments"
        type="assignments"
        :data="assignments"
        :columns="assignmentColumns"
        :loading="loadingAssignments"
        :perPage="perPage"
        :paginate="paginate"
        :actions="actions"
        :currentPage="currentPage"
        :selectedRows="selectedAssignments"
        checkable
        selectorField="ContratoId.LoginID"
        selected
        @returnSelecteds="setSelectedMembers"
        @returnPage="changeLimitPage"
        @returnCurrentPage="changeCurrentPage"
        class="animate__animated animate__fadeInUp"
      />
    </section>
    <section class="my-container" v-if="existReassigments">
      <ReAssignment
        :reAssignment="reAssignments"
        @reasingCompleted="reasingCompleted"
        @discardReasign="discardReasign"
      />
    </section>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
const API_URL = process.env.VUE_APP_API_URL;
const SHORT_URL = process.env.VUE_APP_SHORT_URL;
import i18n, { activate, type } from "@/utils/i18n";
import HeaderPage from "@/components/HeaderPage.vue";
import TableList from "@/components/TableList.vue";
import AssignTable from "@/components/AssigmentTable.vue";
import Filters from "@/components/Filters.vue";
import FilterForm from "@/components/FilterForm.vue";
import ReAssignment from "@/components/ReAssignment.vue";

export default {
  data() {
    return {
      title: "Cartera de clientes",
      role: localStorage.getItem("role"),
      userId: localStorage.getItem("userId"),
      fullAccess: ["ultrauser", "operator"],
      /* Construcción */
      loadingMembers: false,
      loadingStaff: false,
      loaded: false,
      members: [],
      staff: [],
      columnsMembers: [],
      columnsStaff: [],
      isSelectingMembers: true,
      isSelectingStaff: false,
      isAssigning: false,
      checkingAll: false,
      activeTab: 0,

      uuidv4,

      /* Selector */
      selectedMembers: [],
      selectedStaff: [],
      /* Paginador */
      currentPage: 1,
      perPage: 10,
      paginate: {},
      currentPageStaff: 1,
      perPageStaff: 10,
      paginateStaff: {},
      /* Language */
      i18n,
      iso: localStorage.getItem("iso"),
      /* Filters */
      itemsFilters: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
      },
      advancedFiltersFields: [
        "Nombre",
        "ApellidoPaterno",
        "Email",
        "LoginID",
        "nroContract",
        "FechaRegistro",
        "FechaProcesable",
        "FechaRenovacion",
        "FechaCancelacion",
        "club",
        "status",
        "onlyHeadlines",
        "language",
        "contractStatus",
      ],
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },
      /* ASIGNACIONES */
      loadingAssignments: false,
      assignments: [],
      selectedAssignments: [],
      assignmentColumns: [
        { field: "ContratoId.Numero", label: "Nro. Contrato", type: "text" },
        { field: "ContratoId.LoginID", label: "LoginID", type: "text" },
        { field: "ContratoId.ClubId.Nombre", label: "Club", type: "text" },
        {
          field: "ContratoId.FechaRenovacion",
          label: "Fecha de renovación",
          type: "date",
        },
      ],
      actions: {
        label: "Acciones",
        items: [
          {
            icon: "copy",
            color: "is-info",
            exec: (item) => this.copyLink(item.ContratoId.Id),
          },
          {
            icon: "paper-plane",
            color: "is-success",
            exec: (item) => this.sendEmailPaymentLink(item.ContratoId.Id),
          },
        ],
      },
      advancedAssignFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRenovacion: [],
      },
      /* REASSIGNMENTS */
      existReassigments: false,
      reAssignments: [],

      /* TYPE STAFF */

      typeStaff: "renewals",
      jump: false,
    };
  },
  components: {
    HeaderPage,
    TableList,
    FilterForm,
    AssignTable,
    Filters,
    ReAssignment,
  },
  mounted: function () {
    console.log(this.role);
    this.title = this.i18n.customerPortfolio[this.iso];
    if (this.fullAccess.includes(this.role)) {
      this.listAllStaff();
    } else {
      this.getAssignaments();
    }
    this.buildColumns();
    this.addFechaAsignacion();
  },

  methods: {
    async getAssignaments(filters = {}) {
      this.loadingAssignments = true;
      try {
        let query = `?limit=${this.perPage}&page=${this.currentPage}&staffId=${
          this.activeTab == 1 ? this.advancedAssignFilters.staff : this.userId
        }`;
        const { data } = await axios.post(
          `${API_URL}/assignments/list${query}`,
          filters
        );
        this.assignments = data?.portfolios || [];
        this.paginate = data?.paginate;
        this.loadingAssignments = false;
      } catch (error) {
        console.log(error);
        this.loadingAssignments = false;
      }
    },
    async listAllMembers(filters = {}, isState = false, state = {}) {
      this.loaded = false;
      try {
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}&type=renewal`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.paginate = data?.paginate;
          this.loadingMembers = false;
          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("membersRenewals", JSON.stringify(stateMembers));
          this.loaded = true;
        }
      } catch (err) {
        this.loadingMembers = false;
        this.loaded = true;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async listAllStaff() {
      try {
        this.loadingStaff = true;
        const { data } = await axios.post(
          `${API_URL}/system/list-staff?assignment=true`,
          {
            StaffModulo: this.typeStaff,
          }
        );
        this.staff = data?.users || [];
        this.paginateStaff = data?.paginate;
        this.loadingStaff = false;
      } catch (err) {
        this.loadingStaff = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    buildColumns() {
      this.columnsMembers = [
        {
          field: "ContratoId.Numero",
          label: this.i18n.nroContract[this.iso],
          type: "text",
        },
        {
          field: "ContratoId.LoginID",
          label: "LoginID",
          type: "text",
        },
        {
          field: "ContratoId.ClubId.Nombre",
          label: "Club",
          type: "text",
        },
        {
          field: "ContratoId.FechaRenovacion",
          label: this.i18n.renovationDate[this.iso],
          type: "date",
        },
      ];

      this.columnsStaff = [
        { field: "Nombre", label: "Nombre", type: "text" },
        { field: "Apellido", label: "Apellido Paterno", type: "text" },
        { field: "Idioma", label: "Idioma", type: "text" },
        { field: "Email", label: "Email", type: "text" },
        {
          field: "assignmentsCount",
          label: "Asignaciones Actuales",
          type: "text",
        },
      ];
    },
    addFechaAsignacion() {
      if (this.role != "staff") {
        this.assignmentColumns.push({
          field: "assignedAt",
          label: "Fecha de asignacion",
          type: "date",
        });
      }
    },

    /* Returns */
    setSelected(returned) {
      if (returned.type === "members") {
        this.selectedMembers = returned.data;
      }
      if (returned.type === "staff") {
        this.selectedStaff = returned.data;
      }
    },
    changeLimitPage(limit) {
      this.perPage = limit;
      if (this.activeTab == 0 && this.role !== "staff") {
        this.listAllMembers(this.advancedFilters);
      } else if (this.activeTab == 1) {
        this.getAssignaments(this.advancedAssignFilters);
      } else {
        this.getAssignaments(this.advancedAssignFilters);
      }
    },
    changeCurrentPage(page) {
      this.currentPage = page;
      if (this.activeTab == 0 && this.role !== "staff") {
        this.listAllMembers(this.advancedFilters);
      } else if (this.activeTab == 1) {
        this.getAssignaments(this.advancedAssignFilters);
      } else {
        this.getAssignaments(this.advancedAssignFilters);
      }
    },
    /* Filter */
    advancedSearch() {
      console.log(this.advancedAssignFilters);
      this.currentPage = 1;
      if (this.activeTab == 0 && this.role !== "staff") {
        this.listAllMembers(this.advancedFilters);
      } else if (this.activeTab == 1) {
        this.getAssignaments(this.advancedAssignFilters);
      } else {
        this.getAssignaments(this.advancedAssignFilters);
      }
    },
    async clearFilters() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
      };
      this.advancedAssignFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRenovacion: [],
      };
      if (this.fullAccess.includes(this.role)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.getAssignaments(this.advancedAssignFilters);
      }
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          filters
        );

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    async checkAllMembers(
      filters = this.advancedFilters,
      isState = false,
      state = {}
    ) {
      if (this.activeTab == 1 || this.role == "staff") {
        try {
          let query = `?limit=${1000000}&page=${this.currentPage}&staffId=${
            this.activeTab == 1 ? this.advancedAssignFilters.staff : this.userId
          }`;
          const { data } = await axios.post(
            `${API_URL}/assignments/list${query}`,
            filters
          );

          data?.portfolios.map((assignment) => {
            this.selectedAssignments.push(assignment.ContratoId.LoginID);
          });
        } catch (error) {
          console.log(error);
          this.loadingAssignments = false;
        }
      } else {
        try {
          this.checkingAll = true;
          if (isState) {
            this.itemsFilters = state.itemsFilters;
          } else {
            let query = `?limit=${10000000}&page=${this.currentPage}`;
            const { data } = await axios.post(
              `${API_URL}/catcher/members/list-all${query}`,
              filters
            );
            const checkMembers = data?.members || [];

            checkMembers.map((member) => {
              this.selectedMembers.push(member.ContratoId.LoginID);
            });
            this.checkingAll = false;
          }
        } catch (err) {
          this.loadingMembers = false;
          this.checkingAll = false;
          console.log(err);
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        }
      }
    },
    async generateAssign() {
      await this.listAllStaff();
      if (this.selectedMembers.length === 0) {
        this.$buefy.toast.open({
          message: "Debe seleccionar al menos un socio",
          type: "is-danger",
        });
        return;
      }
      this.isSelectingStaff = true;
      this.isSelectingMembers = false;
    },
    async generateReassign() {
      this.activeTab = 0;
      await this.listAllStaff();
      if (this.selectedMembers.length === 0) {
        this.$buefy.toast.open({
          message: "Debe seleccionar al menos un socio",
          type: "is-danger",
        });
        return;
      }
      this.loaded = true;
      this.isSelectingStaff = true;
      this.isSelectingMembers = false;
    },
    divideCustomers() {
      this.isAssigning = true;
      this.isSelectingStaff = false;
      this.isSelectingMembers = false;
    },
    async assignmentSave(assignments) {
      axios
        .post(
          `${API_URL}/assignments/create?type=renewal&jump=${this.jump}`,
          assignments
        )
        .then(({ data }) => {
          this.$buefy.toast.open({
            message: "Asignación guardada",
            type: "is-success",
          });
          if (this.jump) {
            this.isAssigning = false;
            this.isSelectingStaff = false;
            this.isSelectingMembers = true;
          } else {
            data.map((record) => {
              this.reAssignments.push(...record.existingAssignments);
            });
            this.selectedMembers = [];
            this.selectedStaff = [];
            if (this.reAssignments.length !== 0) {
              this.existReassigments = true;
              this.isAssigning = false;
            } else {
              this.isAssigning = false;
              this.isSelectingStaff = false;
              this.isSelectingMembers = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$buefy.toast.open({
            message: "Error al guardar asignación",
            type: "is-danger",
          });
        });
    },
    goback() {
      if (this.isSelectingStaff) {
        this.isSelectingStaff = false;
        this.isSelectingMembers = true;
      } else if (this.isAssigning) {
        this.isAssigning = false;
        this.isSelectingStaff = true;
      }
    },

    reasingCompleted(data) {
      this.$buefy.toast.open({
        message: "Reasignación completada",
        type: "is-success",
      });
      this.existReassigments = false;
      this.reAssignments = [];
      this.isSelectingMembers = true;
    },
    discardReasign() {
      this.$buefy.toast.open({
        message: "Reasignación descartada",
        type: "is-danger",
      });
      this.existReassigments = false;
      this.reAssignments = [];
      this.isSelectingMembers = true;
    },
    changeTab(index) {
      console.log(index);
      this.activeTab = index;
      localStorage.setItem("activeTab", this.activeTab);
    },
    lookForDifferentAgents() {
      if (this.typeStaff === "renewals") {
        this.typeStaff = "recovery";
        this.jump = true;
      } else {
        this.typeStaff = "renewals";
        this.jump = false;
      }
      this.listAllStaff();
    },
    setSelectedMembers(loginIds) {
      console.log(loginIds);
      this.selectedAssignments = loginIds.data;
      console.log(this.selectedAssignments);
    },

    async sendRenewalLinks() {
      const result = await axios.post(
        `${API_URL}/renewals/mass/create?type=assign`,
        {
          LoginIDs: this.selectedAssignments,
          userId: localStorage.getItem("userId") || "",
        }
      );

      if (result) {
        alert("Renovaciones enviadas");
        this.selectedAssignments = [];
      }

      console.log(result);
    },

    /* ACTIONS */
    async sendEmailPaymentLink(id) {
      try {
        await axios.get(
          `${API_URL}/paymentlink/send-email/${id}?type=contract`
        );
        this.$buefy.toast.open({
          message: this.i18n.linkCopy__msg2[this.iso],
          type: "is-success",
        });
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al enviar enlace de pago",
          type: "is-danger",
        });
      }
    },
    async copyLink(id) {
      try {
        const { data } = await axios.get(
          `${API_URL}/paymentlink/get-paymentlink/${id}?type=assign`
        );
        navigator.clipboard.writeText(`${SHORT_URL}/${data.paymentlinkId}`);
        this.$buefy.toast.open({
          message: this.i18n.linkCopy__msg1[this.iso],
          type: "is-info",
        });
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al copiar enlace",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.my-container {
  margin: 0 0 3rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

.my-buttons {
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: right;
  gap: 0.5rem;
}

.buttons-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  border-radius: 0.5rem;
}

.member-checker {
  position: relative;
}

.overlayChecking {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000025;
  z-index: 100;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-for-members {
  padding: 7.5rem;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}
.space {
  margin-top: 1.5rem;
}

.contenedor {
  padding: 0 1.5rem;
}
</style>
