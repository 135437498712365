<template>
  <div class="card" style="width: 500px">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ i18n.addPayExternal[iso] }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns">
          <div class="column is-4">
            <b-field
              :label="i18n.typePayment[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !pay.type ? 'is-danger' : ''"
              :message="!passInfo && !pay.type ? 'Este campo es requerido' : ''"
            >
              <b-select expanded v-model="pay.type">
                <option value="linkPayment">Liga de Pago</option>
                <option value="travelVaucher">Travel Vaucher</option>
                <option value="cxcEmployee">CXC Empleado</option>
                <option value="discount">Descuento</option>
                <option value="courtesy">Cortesía</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field
              :label="i18n.idTransaction[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !pay.id ? 'is-danger' : ''"
              :message="!passInfo && !pay.id ? 'Este campo es requerido' : ''"
            >
              <b-input v-model="pay.id"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field
              :label="i18n.amount[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !pay.id ? 'is-danger' : ''"
              :message="!passInfo && !pay.id ? 'Este campo es requerido' : ''"
            >
              <b-input v-model="pay.amount" type="number"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        style="height: auto"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        @click="addPayment"
        :class="`card-footer-item button is-dark is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        ><b>{{ i18n.add[iso] }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../utils/i18n";

export default {
  props: ["numberRef"],
  data() {
    return {
      loading: false,
      labelPosition: "on-border",
      passInfo: true,

      //i18n
      i18n,
      iso: null,

      //
      pay: {
        type: null,
        amount: null,
        id: null,
      },
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },

  methods: {
    addPayment() {
      this.loading = true;
      axios({
        method: "post",
        url: `${API_URL}/payment/add`,
        data: {
          numberRef: this.numberRef,
          ...this.pay,
        },
      })
        .then(({ data }) => {
          console.log(data);
          this.loading = false;
          this.$buefy.toast.open({
            message: "Pago agregado con exito",
            type: "is-success",
          });
          this.$emit("refresh");
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$buefy.toast.open({
            message: "Error al agregar pago",
            type: "is-danger",
          });
        });
    },
  },
};
</script>
