<template>
  <div>
    <b-tabs>
      <b-tab-item label="Advance Filters" icon-pack="fas" icon="filter">
        <div class="filters">
          <b-field
            v-for="(filter, index) in filters"
            :key="index"
            :label="filter.label"
            label-position="on-border"
          >
            <component
              :is="filter.type"
              v-model="localFilters[filter.model]"
              v-bind="filter.props"
            >
            </component>
          </b-field>
          <div class="filter-buttons">
            <b-button
              icon-left="search"
              icon-pack="fas"
              type="is-info"
              @click="emitFilters"
              expanded
              >Search</b-button
            >
            <b-button
              icon-left="eraser"
              icon-pack="fas"
              type="is-danger"
              @click="clearFilters(false)"
              expanded
            ></b-button>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localFilters: this.initializeFilters(),
    };
  },
  methods: {
    initializeFilters() {
      let filters = {};
      this.filters.forEach((filter) => {
        if (filter.type === "b-datepicker") {
          filters[filter.model] = null; // or [] if range
        } else {
          filters[filter.model] = "";
        }
      });
      return filters;
    },
    emitFilters() {
      this.$emit("applyFilters", this.localFilters);
    },
  },
};
</script>

<style scoped>
.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.filter-buttons {
  display: flex;
  gap: 0.5rem;
}

.filter-buttons button:first-child {
  width: 200%;
}
</style>
