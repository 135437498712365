<template>
  <div>
    <HeaderPage :title="i18n.onlineRenewals[iso]" icon="fa-handshake" />
    <section class="my-container">
      <Filters
        :advancedFilters="advancedFilters"
        membersCatcher
        @searchMembers="advancedSearch(false)"
        @clearFilters="clearAdvancedSearch"
      ></Filters>
      <div class="my-hr"></div>
      <div class="my-buttons">
        <b-button
          icon-left="check-square"
          icon-pack="fas"
          v-if="selectedMembers.length > 0"
          type="is-danger"
          @click="uncheckAllMembers()"
        >
          Desmarcar todos
        </b-button>
        <b-button
          icon-left="check-square"
          icon-pack="fas"
          type="is-success"
          @click="checkAllMembers()"
        >
          Marcar todos
        </b-button>
        <b-button
          icon-left="link"
          icon-pack="fas"
          type="is-success"
          @click="sendRenewalLinks()"
        >
          {{ i18n.sendLink[iso] }}
        </b-button>
      </div>
      <div class="my-hr"></div>
      <TableList
        type="members"
        :data="members"
        :columns="columns"
        checkable
        :loading="loadingMembers"
        :perPage="perPage"
        :paginate="paginate"
        :currentPage="currentPage"
        selectorField="ContratoId.LoginID"
        :selectedRows="selectedMembers"
        selected
        @returnSelecteds="setSelectedMembers"
        v-if="loaded && !isSelectStaff"
        class="animate__animated animate__fadeInUp"
      ></TableList>
    </section>
    <!--  <section>
      <b-tabs v-model="activeTab">
        <b-tab-item
          :label="i18n.advancedSearch[iso]"
          icon-pack="fas"
          icon="search"
        >
          <form action="" class="advance-search">
            
          </form>
          <div class="button-container">
            <b-button
              icon-left="search"
              icon-pack="fas"
              type="is-info"
              @click="advancedSearch(false)"
            >
              {{ i18n.search[iso] }}
            </b-button>
          </div>
          <hr />
          <div class="button-container">
            <div>
              <h2>{{ i18n.months[iso][workMonth] }} {{ workYear }}</h2>
            </div>
            <div>
              <b-button
                icon-left="check-square"
                icon-pack="fas"
                type="is-success"
                @click="checkAllMembers()"
              >
                Marcar todos
              </b-button>
              <b-button
                icon-left="link"
                icon-pack="fas"
                type="is-success"
                @click="sendRenewalLinks()"
              >
                {{ i18n.sendLink[iso] }}
              </b-button>
            </div>
            <br />
          </div>
          <hr />
          <div class="members-table">
            <TableList
              :data="members"
              :columns="columns"
              checkable
              :perPage="perPage"
              selectorField="ContratoId.LoginID"
              :selectedRows="selectedMembers"
              @returnSelecteds="setSelectedMembers"
              v-if="loaded && !isSelectStaff"
              class="animate__animated animate__fadeInUp"
            ></TableList>
          </div>
        </b-tab-item>
      </b-tabs>
    </section> -->
    <!-- <pre>{{ formatCheckeds }}</pre> -->
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../utils/i18n";

import ModalEditMember from "./ModalEditMember.vue";
import TableList from "@/components/TableList.vue";
import HeaderPage from "@/components/HeaderPage.vue";
import Filters from "@/components/Filters.vue";
// import ModalCommentMember from "./ModalCommentMember.vue";

const today = new Date();

export default {
  components: {
    ModalEditMember,
    TableList,
    Filters,
    HeaderPage,
    // ModalCommentMember,
  },
  data() {
    return {
      loaded: false,
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      checkingAll: false,
      /* Paginate */
      paginate: {},
      currentPage: 1,
      perPage: 10,
      /*  */
      total: 0,
      loadingExportMembers: false,
      activeTab: 0,
      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      //Filters
      tabFilters: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      loadingClubes: false,
      itemsFilters: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },

      columns: [],
      columnsStaff: [],

      isSelectStaff: false,

      /**
       * Members
       */

      members: [],
      staff: [],
      loadingMembers: false,
      selectedMembers: [],
      selectedStaff: [],

      /**
       * Modal
       */

      isActiveModalEditMember: false,
      dataModalEditMember: null,
      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
      workMonth: moment().month(),
      workYear: moment().year(),
    };
  },
  computed: {
    formatCheckeds() {
      return JSON.stringify(this.selectedMembers);
    },
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
    this.buildColumns();

    //PerPage
    const perPageLocalStorage = parseInt(
      localStorage.getItem("perPageReserves")
    );
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Tabs
    const tabFilters = localStorage.getItem("tabFilters");
    if (tabFilters === 1 || tabFilters === "1") this.tabFilters = 1;

    //Members
    this.listAllClubes();
    this.listAllStaff();
    let stateMembers = localStorage.getItem("membersRenewals");
    stateMembers = JSON.parse(stateMembers);
    if (
      stateMembers &&
      stateMembers?.exp &&
      stateMembers.exp > moment().valueOf() &&
      stateMembers.lastSearchMembers &&
      stateMembers.lastSearchMembers !== "null"
    ) {
      this.searchMembers = stateMembers.lastSearchMembers;
      this.passValidateSearch = true;
      this.listAllMembers({}, true, stateMembers);
    } else this.listAllMembers();
  },

  watch: {
    selectedMembers: {
      handler() {
        console.log(this.selectedMembers);
      },
      deep: true,
    },

    perPage: {
      handler() {
        this.savePerPage();
        this.listAllMembers();
      },
    },
  },
  methods: {
    filterStage() {
      let startRange = moment().add(2, "months").startOf("month");
      let endRange = moment().add(2, "months").endOf("month");

      this.advancedFilters.FechaRenovacion = [
        startRange.toDate(),
        endRange.toDate(),
      ];

      this.listAllMembers(this.advancedFilters);
    },
    setSelectedMembers(loginIds) {
      console.log(loginIds);
      this.selectedMembers = loginIds.data;
    },
    setSelectedStaff(staff) {
      console.log(staff);
      this.selectedStaff = staff;
    },
    buildColumns() {
      this.columns = [
        {
          field: "ContratoId.Numero",
          label: this.i18n.nroContract[this.iso],
          type: "text",
        },
        {
          field: "ContratoId.LoginID",
          label: "LoginID",
          type: "text",
        },
        {
          field: "ContratoId.ClubId.Nombre",
          label: "Club",
          type: "text",
        },
        {
          field: "ContratoId.FechaRenovacion",
          label: this.i18n.renovationDate[this.iso],
          type: "date",
        },
      ];

      this.columnsStaff = [
        { field: "nombre", label: "Nombre", type: "text" },
        { field: "apellidopat", label: "Apellido Paterno", type: "text" },
        { field: "id_idioma", label: "Idioma", type: "text" },
      ];
    },

    async listAllMembers(filters = {}, isState = false, state = {}) {
      this.loaded = false;
      try {
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}&type=renewal`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );

          this.members = data?.members || [];
          this.paginate = data?.paginate;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;

          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("membersRenewals", JSON.stringify(stateMembers));
          this.loaded = true;
        }
      } catch (err) {
        this.loadingMembers = false;
        this.loaded = true;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async listAllStaff() {
      try {
        this.loadingMembers = true;
        const { data } = await axios.post(`${API_URL}/system/list-staff`);
        this.staff = data?.users || [];
        this.loadingMembers = false;
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    async sendRenewalLinks() {
      const result = await axios.post(
        `${API_URL}/renewals/mass/create?type=online`,
        {
          LoginIDs: this.selectedMembers,
        }
      );

      if (result) {
        alert("Renovaciones enviadas");
      }

      console.log(result);
    },
    async checkAllMembers(
      filters = this.advancedFilters,
      isState = false,
      state = {}
    ) {
      try {
        this.checkingAll = true;
        if (isState) {
          this.itemsFilters = state.itemsFilters;
        } else {
          let query = `?limit=${10000000}&page=${this.currentPage}`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          const checkMembers = data?.members || [];

          checkMembers.map((member) => {
            this.selectedMembers.push(member.ContratoId.LoginID);
          });
          this.checkingAll = false;
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
        this.checkingAll = false;
      }
    },

    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    uncheckAllMembers() {
      this.selectedMembers = [];
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      }
    },
    basicSearch(isExport = false) {
      if (isExport) {
        if (this.searchMembers && this.searchMembers !== "")
          this.exportAllMembers({
            rgx: this.searchMembers,
          });
        else this.exportAllMembers({});
      } else {
        this.currentPage = 1;
        if (this.searchMembers && this.searchMembers !== "")
          this.listAllMembers({
            rgx: this.searchMembers,
          });
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearAdvancedSearch() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaCancelado: [],
        FechaRenovacion: [],
        club: null,
        status: null,
        onlyHeadlines: null,
        language: null,
        contractStatus: null,
      };
      this.listAllMembers();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("MMM/DD/YYYY").toUpperCase();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          filters
        );

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    /* Asignacion a agente */
    generateAssign() {
      if (this.selectedMembers.length === 0) {
        this.$buefy.toast.open({
          message: "Debe seleccionar al menos un socio",
          type: "is-danger",
        });
        return;
      }
      this.isSelectStaff = true;
      console.log(this.selectedMembers);
    },
  },
};
</script>

<style>
.advance-search {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.button-container h2 {
  font-size: 28px;
  font-weight: 700;
}

.button-container div:nth-child(2) {
  display: flex;
  gap: 1rem;
  justify-content: right;
  flex-grow: 1;
}

.my-buttons {
  gap: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.checkeds {
  max-width: 500px;
  overflow-x: scroll;
}
</style>
